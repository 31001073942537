import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import Logos from "../assets/images/Group 138.svg";
import { signUpAPI } from "../apis/api";
import useLocalStorage from "use-local-storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Hourglass } from "react-loader-spinner";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import secureLocalStorage from "react-secure-storage";
import Button from "react-bootstrap/Button";

const Signup = () => {
  const [spinner, setSpinner] = useState(false);
  const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDark, setIsDark] = useLocalStorage("isDark", preference);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setconfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [nameError, setnameError] = useState("");
  const [emailError, setemailError] = useState("");
  const [confirmpasswordError, setconfirmPasswordError] = useState("");

  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  useEffect(() => {
    setnameError("");
    setemailError("");
    setPasswordError("");
    setconfirmPasswordError("");
    setError("");
    setMessage("");
  }, []);

  function isValidEmail(email) {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }
  function isValidName(name) {
    const pattern = /^[A-Za-z\s]+$/;
    return pattern.test(name);
  }

  useEffect(() => {
    // setnameError(name ? { data: "", status: true } : "Required*");
    setnameError(
      name === ""
        ? "Required*"
        : isValidName(name)
        ? { data: "", status: true }
        : "Only alphabets are allowed"
    );
    setemailError(
      email == ""
        ? "Required*"
        : isValidEmail(email)
        ? { data: "", status: true }
        : "email invalid format"
    );

    setPasswordError(
      password == ""
        ? "Required*"
        : password.length < 8
        ? "password must be 8 char long*"
        : { data: "", status: true }
    );

    setconfirmPasswordError(
      confirmpassword == ""
        ? "Required*"
        : confirmpassword != password
        ? "password dont match"
        : { data: "", status: true }
    );
  }, [name, email, password, confirmpassword]);

  const checkValidations = () => {
    if (nameError && emailError && passwordError && confirmpasswordError) {
      if (
        nameError.status &&
        emailError.status &&
        passwordError.status &&
        confirmpasswordError.status
      ) {
        setSpinner(true);

        console.log("login");
        signUpAPI(name, email, password, confirmpassword)
          .then((response) => {
            setSpinner(false);
            console.log("response in signup:", response);
            // alert("sign up succefull");
            notify("Enter OTP to verify your account", "success");
            setTimeout(()=>{
            notify("Please check your spam folder if you do not receive the email","success")

            },2000)
            // navigate("/signin");
            navigate("/accountrecovery", {
              state: { email: email, screen: "signUp" },
            });
          })
          .catch((err) => {
            console.log(err.response.data.message);
            setSpinner(false);
            notifyError(`Failed to Signup! ${err?.response?.data?.message}`);
            // console.log("error in signup:", err);
            // alert(err);
          });

        // alert("login");
        // navigate("/signin");
      }
    } else {
    }

    console.log(
      "true status outside event======>",
      nameError.status,
      emailError.status,
      passwordError.status,
      confirmpasswordError.status
    );
  };

  const handleSignUp = async () => {
    if (
      name === "" ||
      email === "" ||
      password === "" ||
      confirmpassword === ""
    ) {
      notify("Please fill all the fields", "error");
      return;
    }
    // setSpinner(true);
    checkValidations();

    console.log("The error is:", error);
  };

  const myInlineStyles = {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    color: "red",
    fontFamily: "Arial",
  };
  const notify = (message, type = "success") => {
    toast[type](message, {
      position: "top-right",
      autoClose: 5000,
    });
  };
  const notifyError = (message, type = "error") => {
    toast[type](message, {
      position: "top-right",
      autoClose: 2000,
    });
  };

  // Terms and conditions agreement Modals

  const { t } = useTranslation();
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showThirdModal, setShowThirdModal] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const modalRef = useRef(null);

  const handleCloseFirstModal = () => {
    if (termsChecked && privacyChecked) {
      setShowFirstModal(false);
      setShowThirdModal(true);
    } else {
      toast.error("Accept Terms and Privacy policy", {
        toastId: "Agree error",
      });
    }
  };

  const handleCloseSecondModal = () => {
    if (cookiesAccepted) {
      setShowSecondModal(false);
    } else {
      toast.error("Accept Cookies Policy", {
        toastId: "second modal",
      });
    }
  };
  const handleCloseThirdModal = () => {
    setShowThirdModal(false);
    setShowSecondModal(true);
  };

  const handleAcceptCookies = () => {
    setCookiesAccepted(true);
    setShowSecondModal(false);
  };

  const handleTermsChange = () => {
    setTermsChecked(!termsChecked);
  };

  const handlePrivacyChange = () => {
    setPrivacyChecked(!privacyChecked);
  };

  useEffect(() => {
    const loginData = secureLocalStorage.getItem("loginData");
    if (loginData) {
      navigate("/dashboard");
    }
    // setShowFirstModal(true);
  }, []);

  return (
    <>
      <div className="background-account" data-theme={isDark ? "dark" : ""}>
        <div className="signupPage" data-theme={isDark ? "dark" : ""}>
          <div className="signin-content" data-theme={isDark ? "dark" : ""}>
            <div className="signup-logo">
              {/* <img src={Logo} alt="signuplogo" className="custom" /> */}
              <img
                src={Logos}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
                alt="signuplogo"
                className="custom"
              />
              {/* <img src={Logoss} alt="signuplogo" className="custom" /> */}
            </div>
            <div className="signup-card" data-theme={isDark ? "dark" : ""}>
              <div
                className="signupcard-content"
                data-theme={isDark ? "dark" : ""}
              >
                <div className="main-card" data-theme={isDark ? "dark" : ""}>
                  <div className="cardtitle" data-theme={isDark ? "dark" : ""}>
                    <div className="leftside" data-theme={isDark ? "dark" : ""}>
                      <h6 data-theme={isDark ? "dark" : ""}> {t("signUp")}</h6>
                    </div>
                  </div>

                  <div className="card-field" data-theme={isDark ? "dark" : ""}>
                    <div
                      className="firstfield-signup"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <div className="label" data-theme={isDark ? "dark" : ""}>
                        <label htmlFor="" data-theme={isDark ? "dark" : ""}>
                          {t("Enter_your_Name")}
                        </label>{" "}
                        <span style={myInlineStyles} className="span">
                          {/* {nameError == "Required*"
                            ? "Required*"
                            : nameError.data} */}
                          {/* {nameError} */}

                          {nameError == "Required*"
                            ? "Required*"
                            : nameError == "Only alphabets are allowed"
                            ? "Only alphabets are allowed"
                            : nameError.data}
                        </span>
                      </div>
                      <div
                        className="inputfield"
                        data-theme={isDark ? "dark" : ""}
                      >
                        <input
                          type="text"
                          placeholder={t("name")}
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            setMessage(""); // Clear error when user starts typing
                          }}
                          data-theme={isDark ? "dark" : ""}
                        />
                      </div>
                    </div>
                    <div
                      className="firstfield"
                      data-theme={isDark ? "dark" : ""}
                    >
                      {/* <div className="label">
                      <label htmlFor="">Enter your Owner’s Name</label>{" "}
                    </div> */}
                      <div className="label" data-theme={isDark ? "dark" : ""}>
                        <label htmlFor="" data-theme={isDark ? "dark" : ""}>
                          {t("EnterYourEmailAddress")}
                        </label>{" "}
                        <span style={myInlineStyles} className="span">
                          {emailError == "Required*"
                            ? "Required*"
                            : emailError == "email invalid format"
                            ? "email invalid format"
                            : emailError.data}
                        </span>
                      </div>
                      <div
                        className="inputfield"
                        data-theme={isDark ? "dark" : ""}
                      >
                        <input
                          type="text"
                          placeholder={t("Email_address")}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setMessage("");
                          }}
                          data-theme={isDark ? "dark" : ""}
                        />
                      </div>
                    </div>
                    <div
                      className="firstfield"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <div className="label" data-theme={isDark ? "dark" : ""}>
                        <label htmlFor="" data-theme={isDark ? "dark" : ""}>
                          {t("EnterYourPassword")}
                        </label>{" "}
                        <span style={myInlineStyles} className="span">
                          {passwordError == "Required*"
                            ? "Required*"
                            : passwordError == "password must be 8 char long*"
                            ? "password must be 8 char long*"
                            : passwordError.data}
                        </span>
                      </div>
                      <div
                        className="inputfield"
                        data-theme={isDark ? "dark" : ""}
                      >
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setMessage("");
                          }}
                          data-theme={isDark ? "dark" : ""}
                        />
                        <div
                          className="eye-icon"
                          onClick={togglePasswordVisibility}
                        >
                          {/* <Icon
                          icon="octicon:eye-24"
                          color="gray"
                          width="18"
                          height="18"
                        /> */}
                          {showPassword ? (
                            <Icon
                              icon="octicon:eye-24"
                              color="gray"
                              width="18"
                              height="18"
                            />
                          ) : (
                            <Icon
                              icon="tabler:eye-off"
                              color="gray"
                              width="18"
                              height="18"
                            />
                          )}
                        </div>
                      </div>
                      {/* <span style={myInlineStyles} className="span">
                      {passwordError}
                    </span> */}
                    </div>
                    <div
                      className="firstfield"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <div className="label" data-theme={isDark ? "dark" : ""}>
                        <label htmlFor="" data-theme={isDark ? "dark" : ""}>
                          {t("Confirm_your_Password")}
                        </label>{" "}
                        <span style={myInlineStyles} className="span">
                          {confirmpasswordError === "Required*"
                            ? "Required*"
                            : confirmpasswordError == "password dont match"
                            ? "password dont match"
                            : confirmpasswordError.data}
                        </span>
                      </div>
                      <div
                        className="inputfield"
                        data-theme={isDark ? "dark" : ""}
                      >
                        <input
                          type={showPassword1 ? "text" : "password"}
                          placeholder={t("Password")}
                          onChange={(e) => {
                            setconfirmPassword(e.target.value);
                            setMessage("");
                          }}
                          data-theme={isDark ? "dark" : ""}
                        />
                        <div
                          className="eye-icon"
                          onClick={togglePasswordVisibility1}
                        >
                          {showPassword1 ? (
                            <Icon
                              icon="octicon:eye-24"
                              color="gray"
                              width="18"
                              height="18"
                            />
                          ) : (
                            <Icon
                              icon="tabler:eye-off"
                              color="gray"
                              width="18"
                              height="18"
                            />
                          )}
                        </div>
                      </div>
                      {/* <span style={myInlineStyles} className="span">
                      {confirmpasswordError}
                    </span> */}
                    </div>
                  </div>
                  <div
                    className="card-bottom"
                    data-theme={isDark ? "dark" : ""}
                  >
                    <div
                      className="bottom-title"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <p data-theme={isDark ? "dark" : ""}>
                        {t("Already_have_an_Account")}
                        <span
                          onClick={() => {
                            navigate("/signin");
                          }}
                          data-theme={isDark ? "dark" : ""}
                        >
                          &nbsp;{t("LogIn")}
                        </span>
                      </p>
                    </div>
                    <div
                      className="signin-btn"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <button
                        onClick={handleSignUp}
                        data-theme={isDark ? "dark" : ""}
                      >
                        {t("signUp")}
                        {spinner && (
                          <span>
                            {" "}
                            {/* <Spinner
                              animation="border"
                              variant="primary"
                              size="sm"
                            /> */}
                            <Hourglass
                              visible={true}
                              height="15"
                              width="15"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              colors={
                                isDark ? ["#000", "#000"] : ["#fff", "#fff"]
                              }
                              data-theme={isDark ? "light" : "dark"}
                            />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}

        {/* First Modal */}
        <Modal
          show={showFirstModal}
          onHide={handleCloseFirstModal}
          className="termsmodal"
          size="xl"
          centered
          ref={modalRef}
        >
          <Modal.Body className="termsmodal-body">
            <div className="terms-modal">
              <div className="terms-header">
                <span>{t("termsOfService")}</span>
              </div>
              <div className="middle-body h-auto">
                <div className="middle-top">
                  <p>{t("LawvocateAIDisclaimer")}</p>
                  <p>
                    {t("expressAgreementRequirement")}
                    <span>{t("PrivacyPolicy")}</span>
                    {t("and")}
                    <span>{t("termsOfUse")}</span>.{t("confirmUnderstanding")}
                  </p>
                </div>
              </div>
              <div className="checkbox-termsmodal">
                <div className="form-check formcheck-parent">
                  <input
                    className="form-check-input formcheckinput"
                    type="checkbox"
                    id="tos"
                    checked={termsChecked}
                    onChange={handleTermsChange}
                  />
                  <label
                    className="form-check-label formchecklabel"
                    htmlFor="tos"
                  >
                    <strong>{t("termsOfService")}</strong>
                  </label>
                  <a href="/termsofservice">(See Terms and conditions)</a>
                </div>
                <div className="form-check formcheck-parent">
                  <input
                    className="form-check-input formcheckinput"
                    type="checkbox"
                    id="pp"
                    checked={privacyChecked}
                    onChange={handlePrivacyChange}
                  />
                  <label
                    className="form-check-label formchecklabel"
                    htmlFor="pp"
                  >
                    <strong> {t("PrivacyPolicy")}</strong>
                  </label>
                  <a href="/privacy-policy">(See Privacy Policy)</a>
                </div>
              </div>

              <div className="term-botton">
                <button
                  className="agree"
                  onClick={handleCloseFirstModal}
                  // disabled={!(termsChecked && privacyChecked)}
                >
                  Agree
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Second Modal */}
        <Modal
          show={showSecondModal}
          onHide={handleCloseSecondModal}
          className="termsmodal cookies-modal"
          size="lg"
          centered
          backdrop="static" // Prevent closing when clicking outside
          keyboard={false} // Prevent closing with the escape key
          ref={modalRef}
        >
          {/* Second Modal content */}
          <Modal.Body className="termsmodal-body cookies-modal-body">
            <div className="terms-modal">
              <div className="middle-body h-auto">
                <p className="text-center">{t("cookiesConsentMessage")}</p>
              </div>
              <div className="term-botton">
                <Button
                  className="accept-cookies"
                  onClick={handleAcceptCookies}
                >
                  {t("acceptCookies")}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Third Modal */}
        <Modal
          show={showThirdModal}
          onHide={handleCloseThirdModal}
          className="termsmodal cookies-modal"
          size="lg"
          centered
          backdrop="static" // Prevent closing when clicking outside
          keyboard={false} // Prevent closing with the escape key
          ref={modalRef}
        >
          {/* Third Modal content */}
          <Modal.Body className="termsmodal-body cookies-modal-body">
            <div className="terms-modal">
              <div className="middle-body h-auto">
                <p className="text-center">
                  Are you sure, you want to accept our&nbsp;
                  <a href="/termsofservice">Terms and Conditions?</a>
                </p>
              </div>
              <div className="term-botton">
                <Button
                  className="accept-cookies"
                  onClick={handleCloseThirdModal}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Signup;
