import React, { useState } from "react";
import "./navbar.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import Homelogo from "../../assets/images/homepage-logo.svg";
import { t } from "i18next";

function Navbar() {
  const location = useLocation();
  const pathsToHide = ["/b2c"];
  const shouldHideComponent = pathsToHide.includes(location.pathname);

  return (
    <>
      <div className="navbar-section">
        <nav className="navbar navbar-expand-lg navbar-main">
          <div className="container-fluid navbar-primary">
            <Link className="navbar-brand" to="/">
              <img src={Homelogo} alt="..." />{" "}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <Icon
                icon="mdi:hamburger-menu"
                color="black"
                width="25"
                height="25"
              />
              {/* <span className="navbar-toggler-icon"></span> */}
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav ms-auto  navbar-nav-scroll nav-ul">
                <li className="nav-item">
                  <NavLink
                    className="nav-link active"
                    aria-current="page"
                    to={shouldHideComponent ? "/aboutus-b2c" : "/aboutus"}
                  >
                    {t("AboutUs")}
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to={shouldHideComponent ? "/services-b2c" : "/services"}
                  >
                    {t("Services")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact">
                    {t("Contact")}
                  </a>
                </li>
                {/* <li className="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t("More")}
                  </a> 
                   <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>

                    <li>
                      <a class="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul> */}
                {/* </li> */}
              </ul>
              <form className="d-flex form-search" role="search">
                <Link to={"/signup"}>
                  {" "}
                  <button className="sign-up" type="submit">
                    {t("signup")}
                  </button>
                </Link>

                <Link to={"/signin"}>
                  <button class=" Log-in" type="submit">
                    {t("Login")}
                  </button>
                </Link>
              </form>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
