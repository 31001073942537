import React from "react";
import "./privacy.css";

import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import { useTranslation } from "react-i18next";
import { ChangeLanguageButton } from "../../i18n/changeLanguage";

// The security of Your Personal Data is important to Us, but remember
// that no method of transmission over the Internet, or method of
// electronic storage is 100% secure. While We strive to use commercially
// acceptable means to protect Your Personal Data, We cannot guarantee
// its absolute security.
function PrivacyPolicy() {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />

      <div className="privacyPolicy container">
        <h3>{t("PrivacyPolicy")}</h3>
        <p className="mt-5">{t("privacyPolicyDescription")}</p>
        <h6>{t("interpretationAndDefinitions")}</h6>
        <h6>{t("interpretation")}</h6>
        <p>{t("capitalizedWordsDefinition")}</p>
        <h6>{t("definitions")}</h6>
        <p>{t("privacyPolicyPurpose")}</p>
        <ul>
          <p>
            <strong>{t("account")}</strong>
            {t("uniqueAccount")}
          </p>
          <p>
            <strong>{t("affiliate")}</strong> {t("entityControl")}
          </p>
          <p>
            <strong>{t("application")} </strong> {t("lawvocateAI")}
          </p>
          <p>
            <strong>{t("company")}</strong> {t("Company")}
          </p>
          <p>
            <strong>{t("country")} </strong>
            {t("Country")}
          </p>
          <p>
            <strong>{t("device")} </strong>
            {t("Device")}
          </p>
          <p>
            <strong> {t("personalData")}</strong>
            {t("PersonalData")}
          </p>
          <p>
            <strong>{t("service")}</strong>
            {t("refersToTheApplication")}
          </p>
          <p>
            <strong>{t("serviceProvider")}</strong>
            {t("providerMeans")}
          </p>
          <p>
            <strong>{t("usageData")}</strong> {t("UsageData")}
          </p>
          <p>
            <strong>{t("You")}</strong> {t("You")}
          </p>
        </ul>
        <h6>{t("collectingAndUsingYourPersonalData")}</h6>
        <h6>{t("typesOfDataCollected")}</h6>
        <h6>{t("personalData")}</h6>
        <p>{t("personallyIdentifiableInformation")}</p>
        <ul>
          <li>{t("EmailAddress")}</li>
          <li>{t("Name")}</li>
          <li>{t("PhoneNumber")}</li>
          <li>{t("Address")}</li>
        </ul>
        <h6>{t("usageData")}</h6>
        <p>{t("usageDataCollectedAutomatically")}</p>
        <p>{t("Usae_Data_May")}</p>
        <h6>{t("informationCollectedWhileUsingTheApplication")}</h6>
        <p>{t("description")}</p>
        <p>
          {" "}
          <strong>{t("LocationInfo")}</strong>
        </p>
        <p>
          {t("Description")}
          {t("storage")}
        </p>
        <p>{t("AccessInfo")}</p>
        <h6>{t("useOfYourPersonalData")}</h6>
        <p>{t("DataUsagePurpose")}</p>
        <p>
          <strong>{t("ServiceMaintenance")}</strong> {t("includingToMonitor")}
        </p>
        <p>
          <strong> {t("AccountManagement")}</strong>
          {t("RegistrationManagement")}
        </p>
        {/* 3 */}
        <h6>{t("performance_of_contract")}</h6>
        <p>{t("purchase_contract_development_compliance_undertaking")}</p>
        <h6>{t("contactYou")}</h6>
        <p>{t("email_telephone_sms")}</p>
        <p>{t("news_special_offers_general_info")}</p>
        <h6> {t("manage_requests")}</h6>
        <p> {t("request_management")}</p>
        <h6> {t("forbusinessTransfers")}</h6>
        <p>{t("asset_transfer_management")}</p>
        <h6>{t("For_other_purposes")}</h6>
        <p>{t("other_purposes")}</p>
        <h6>{t("sharing_personal_information_situations")}</h6>
        <ul>
          <li>{t("sharingInformationDetails")}</li>
          <li>
            {t("businessTransfers")}
            {t("businessTransfersDetails")}
          </li>
          <li>
            {t("withAffiliates")} {t("withAffiliatesDetails")}
          </li>
          <li>
            {t("withBusinessPartners")}
            {t("withBusinessPartnersDetails")}
          </li>
          <li>
            {t("withOtherUsers")} {t("withOtherUsersDetails")}
          </li>
          <li>
            {t("withYourConsent")}
            {t("withYourConsentDetails")}
          </li>
        </ul>
        <h6>{t("rERC5b4PoR51qWEvAWuJsX6yRVRBvRVta7")}</h6>
        <p>{t("etainYourPersonalData")}</p>
        <p>{t("Usage_Data_for_internal_analysis_purposes")}</p>{" "}
        {/* updates in english */}
        {/* 4 */}
        {/* <h6> {t("performanceContract")}</h6>
        <p>{t("performanceContractDetails")}</p>
        <h6>{t("contactYou")}</h6>
        <p>{t("contactYouDetails")}</p>
        <p>{t("provideInformationDetails")}</p>
        <h6> {t("manageRequests")}</h6>
        <p> {t("manageRequestsDetails")}</p>
        <h6> {t("forbusinessTransfers")}</h6>
        <p>{t("forbusinessTransfersDetails")}</p>
        <h6> {t("otherPurposes")}</h6>
        <p>{t("otherPurposesDetails")}</p>
        <h6>{t("sharingInformation")}</h6>
        <p>{t("sharingInformationDetails")}</p>
        <p>
          {t("businessTransfers")}
          {t("businessTransfersDetails")}
        </p>
        <p>
          {t("withAffiliates")}
          {t("withAffiliatesDetails")}
        </p>
        <p>
          {t("withBusinessPartners")}
          {t("withBusinessPartnersDetails")}
        </p>
        <p>
          {t("withOtherUsers")} {t("withOtherUsersDetails")}
        </p>
        <p>
          {t("withYourConsent")} {t("withYourConsentDetails")}
        </p> */}
        <h6> {t("Transfer_of_Your_Personal_Data")}</h6>
        <p>{t("paraOf_Transfer_of_Your_Personal_Data")}</p>
        <h6> {t("Delete_Your_Data")}</h6>
        <p>{t("paraOf_Delete_Your_Data")}</p>
        <h6> {t("Disclosure_of_Your_Data")}</h6>
        {/* <p>{t("paraOf_Disclosure_of_Your_Data")}</p> */}
        <h6> {t("Business_Transactions")}</h6>
        <p>{t("ParaOf_Business_Transactions")}</p>
        <h6> {t("Law_Enforcement")}</h6>
        <p>{t("ParaOf_Law_Enforcement")}</p>
        <h6> {t("retentionPersonalData")}</h6>
        <p>{t("TheCompanywillretain")}</p>
        {/* 5 */}
        <ul>
          <li>{t("complyWithALegalObligation")}</li>
          <li>{t("defendRightsProperty")}</li>
          <li>{t("investigateWrongdoing")}</li>
          <li>{t("personalSafety")}</li>
          <li>{t("legalLiability")}</li>
        </ul>
        <h6>{t("securityOfYourPersonalData")}</h6>
        <p>{t("The_security_of_Your")}</p>
        <h6>{t("childrensPrivacy")}</h6>
        <p>{t("Our_Service_does")}</p>
        <h6>{t("linksToOtherWebsites")}</h6>
        <p>{t("Our_Service_may")}</p>
        <h6>{t("changesToThisPrivacyPolicy")}</h6>
        <p>{t("We_may_update_Our_Privacy_Policy")}</p>
        <h6>{t("contactUs")}</h6>
        <ul>
          <li>{t("contact_Us")}</li>
          <li>{t("email_Contact")}</li>
          <li>{t("websiteContact")}</li>
        </ul>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
