import React from "react";
import Card from "./card";
import "./advicecard.scss";
import Legalservice from "../../assets/images/legal-service.png";
import Startupadvice from "../../assets/images/startup-advice.png";
import growthadvice from "../../assets/images/growth-strat.png";
import actionVideo from "../../assets/videos/updatedf2.mp4";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const Advicecard = (props) => {
  const [head, setHead] = useState(true);
  const { t } = useTranslation();
  return (
    <>
      <div className={`advicecard-section  ${props.className}`}>
        <div className="container">
          <div>
            <h5 style={{ textTransform: "none" }}>{t("meetLawvocateAI")}</h5>

            <p className="fw-normal advicecard-p">{t("watchVideo")}</p>
          </div>

          <div className="section-content">
            <div className="vedio">
              {/* <video width="320" height="240" controls>
              <source src={actionVideo} type="video/mp4" />
              {t("videoNotSupported")}
            </video> */}
              {/* <video
              width="320"
              height="240"
              className=" "
              src={actionVideo}
              muted
              autoPlay
              loop
              section-content
            /> */}
              <video autoPlay loop muted playsInline>
                <source src={actionVideo} type="video/mp4" />
              </video>
            </div>
            {/* <Card
            img={Legalservice}
            heading="Business Legal Services"
            subheading="Get professional legal advice for your business."
          /> */}
            {/* <Card
            img={Startupadvice}
            heading="Startup Advice"
            subheading="Expert guidance for starting your business journey."
          /> */}
            {/* <Card
            img={growthadvice}
            heading="Growth Strategies"
            subheading="Unlock the potential of your business with growth strategies"
          /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Advicecard;
