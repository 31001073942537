import React, { useState } from "react";
import "./subscriptiontabs.scss";
import useLocalStorage from "use-local-storage";
import { useTranslation } from "react-i18next";
import Dashboardplan from "../dashboardplan"
import Pricingtab from "../../../pricingtab/pricingtab";
import Price from "../../../pricingtab/price";
import YearlyPrice from "../../../pricingtab/yearlyPrice";
import Subscriptionplan1 from "./subscriptionplan1";
import Subscriptionplan2 from "./subscriptionplan2";

const Subscriptiontabs = () => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState("Monthly");
  const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDark, setIsDark] = useLocalStorage("isDark", preference);
  const toggleContent = (section) => {
    setActiveSection(section);
  };
  return (
    <div className="dashboard-pricetabs">
      <div className="pricingtabs-dashboard">
        <div className="pricingtabcontent-dashboard">
          <div className="setingHeader">
            <div className="settinghContent">
              <div className="toggle-containers">
                <button
                  className={`toggle-button ${activeSection === "Monthly" ? "active" : ""
                    }`}
                  onClick={() => toggleContent("Monthly")}
                  data-theme={isDark ? "dark" : ""}
                >
                  {t("Monthly")}
                </button>
                <button
                  className={`toggle-button annual-plan ${activeSection === "Yearly" ? "active" : ""
                    }`}
                  onClick={() => toggleContent("Yearly")}
                  data-theme={isDark ? "dark" : ""}
                >
                  {t("Annual_plan")}
                  {/* <span className="good" data-theme={isDark ? "dark" : ""}>
                    {t("Two_months_free")}
                  </span> */}
                </button>
              </div>

              <div className="span-container" data-theme={isDark ? "dark" : ""}>
                {activeSection === "Monthly" && <Subscriptionplan1 />}
                {activeSection === "Yearly" && <Subscriptionplan2 />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscriptiontabs;
