import React, { useState, useRef, useEffect } from "react";
import Logos from "../assets/images/Group 138.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { forgotPasswordApi, resendOtpdApi, verifyRecoveryCodeApi } from "../apis/api";
import useLocalStorage from "use-local-storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Hourglass } from "react-loader-spinner";
import Countdown from "react-countdown";
import { verifySignUpOTP } from "../apis/api";

const Accountrecovery = () => {
  const [spinner, setSpinner] = useState(false);
  const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDark, setIsDark] = useLocalStorage("isDark", preference);
  const { state } = useLocation();
  const historyData = state || {};
  const navigate = useNavigate();
  const [showResendMessage, setShowResendMessage] = useState(false);
  // const [recoveryCode, setRecoveryCode] = useState("");

  // const handleChange = (event) => {
  //   setRecoveryCode(event.target.value);
  const [otpDigits, setOtpDigits] = useState(["", "", "", ""]);
  const inputRefs = useRef([
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]);
  const handleChange = (index, event) => {
    const value = event.target.value;
    // Allow only numeric values
    if (/^[0-9]*$/.test(value)) {
      setOtpDigits((prevDigits) => {
        const newDigits = [...prevDigits];
        newDigits[index] = value;
        return newDigits;
      });

      // Move to the next input automatically
      if (index < inputRefs.current.length - 1 && value !== "") {
        inputRefs.current[index + 1].current.focus();
      }
    } else if (value === "") {
      // Move back to the previous input when a digit is removed
      if (index > 4) {
        inputRefs.current[index - 1].current.focus();
      }
    }
  };
  const handlePaste = (event) => {
    const pastedData = event.clipboardData.getData("Text").split("");
    // Update the state with pasted data
    setOtpDigits(pastedData.slice(0, otpDigits.length));
  };

  useEffect(() => {
    // Focus on the first input when the component mounts
    inputRefs.current[0].current.focus();
  }, []);
  const handleContinue = () => {
    setSpinner(true);
    const otpCode = otpDigits.join("");
    console.log("historyData.state.email::", otpCode);

    historyData.screen == "forgetMail"
      ? verifyRecoveryCodeApi(historyData.email, otpCode)
          .then((response) => {
            setSpinner(false);
            console.log("response in verify Code:", response);
            notify("Otp matched", "success");
            navigate("/accountreset", {
              state: {
                email: historyData.email,
                otp: otpCode,
              },
            });
          })
          .catch((error) => {
            setSpinner(false);
            console.log("error in verify Code:", error);
            // alert("otp is incorrect");
            notify("Otp Is Incorrect", "error");
          })
      : verifySignUpOTP(historyData.email, otpCode)
          .then((response) => {
            setSpinner(false);
            console.log("response in verify Code:", response);
            notify("Otp matched", "success");
            navigate("/signin", {});
          })
          .catch((error) => {
            setSpinner(false);
            console.log("error in verify Code:", error);
            // alert("otp is incorrect");
            notify("Otp Is Incorrect", "error");
          });

    // Add logic to handle the OTP code (e.g., send it to a server for verification)
    console.log("OTP Code:", otpCode);
    // Add your navigation logic or further processing here
  };
  const handleResendOtp = () => {
    setSpinner(true);
    const otpCode = otpDigits.join("");
    console.log("historyData.state.email::", otpCode);
    if (historyData.screen == "forgetMail"){
      forgotPasswordApi(historyData.email)
      .then((response) => {
        setSpinner(false);
        console.log("response in forgot mail:", response);
        // alert("otp is sent to your mail");
        notify("Otp is sent to your Mail", "success");
        
      })
      .catch((error) => {
        setSpinner(false);
        // toast.error("Invalid emil");
        console.log("error in forgot mail:", error);
        notify(error?.response?.data?.error, "error");
      });
    }
    else{

    
    resendOtpdApi(historyData.email)
    .then((response) => {
      setSpinner(false);
      console.log("response in verify Code:", response);
      notify("Otp sent!", "success");
      // navigate("/accountreset", {
      //   state: {
      //     email: historyData.email,
      //     otp: otpCode,
      //   },
      // });
    })
    .catch((error) => {
      setSpinner(false);
      console.log("error in verify Code:", error);
      // alert("otp is incorrect");
      notify("Otp Is Incorrect", "error");
    })
  }
    
  };

  const notify = (message, type = "success") => {
    toast[type](message, {
      position: "top-right",
      autoClose: 2000,
    });
  };

  return (
    <>
      <div className="background-account" data-theme={isDark ? "dark" : ""}>
        <div className="accountRecovery" data-theme={isDark ? "dark" : ""}>
          <div className="signin-content" data-theme={isDark ? "dark" : ""}>
            <div className="signup-logo">
              {/* <img src={Logo} alt="signuplogo" className="custom" /> */}
              <img src={Logos} alt="signuplogo" className="custom" />
              {/* <img src={Logoss} alt="signuplogo" className="custom" /> */}
            </div>
            <div className="signup-card" data-theme={isDark ? "dark" : ""}>
              <div
                className="signupcard-content"
                data-theme={isDark ? "dark" : ""}
              >
                <div className="main-card" data-theme={isDark ? "dark" : ""}>
                  <div className="cardtitle" data-theme={isDark ? "dark" : ""}>
                    <div className="leftside" data-theme={isDark ? "dark" : ""}>
                      <h6 data-theme={isDark ? "dark" : ""}>
                        {historyData.screen == "forgetMail"
                          ? "Account Recovery"
                          : "Sign Up Confirmation"}
                      </h6>
                    </div>
                    <div
                      className="rightside"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <h6 data-theme={isDark ? "dark" : ""}>
                        {/* To help keep your account safe, we wants to <br /> make
                      sure that it’s really you trying to sign in */}
                        We need to verify that this account is really <br />{" "}
                        belongs to you, this make your account save
                      </h6>
                    </div>
                  </div>
                  <div
                    className="accountRecovery-middle"
                    data-theme={isDark ? "dark" : ""}
                  >
                    <div
                      className="middleTop"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <h6 data-theme={isDark ? "dark" : ""}>
                        Enter 4 Digits Code
                      </h6>
                    </div>
                    <div
                      className="middletext"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <h6 data-theme={isDark ? "dark" : ""}>
                        Enter the 4 digits code that you <br />
                        received on your email.
                      </h6>
                    </div>
                    <div
                      className="middle-code"
                      data-theme={isDark ? "dark" : ""}
                    >
                      {otpDigits.map((digit, index) => (
                        <div
                          className="box1"
                          key={index}
                          data-theme={isDark ? "dark" : ""}
                        >
                          <input
                            ref={inputRefs.current[index]}
                            type="text"
                            value={digit}
                            onChange={(event) => handleChange(index, event)}
                            placeholder="0"
                            maxLength={1}
                            data-theme={isDark ? "dark" : ""}
                          />
                        </div>
                      ))}

                      {/* <div className="box1">
                      <input
                        type="text"
                        value={recoveryCode}
                        onChange={handleChange}
                        placeholder=" 00"
                      />
                    </div>
                    <div className="box1">
                      <input
                        type="text"
                        value={recoveryCode}
                        onChange={handleChange}
                        placeholder=" 00"
                      />
                    </div>
                    <div className="box1">
                      <input
                        type="text"
                        value={recoveryCode}
                        onChange={handleChange}
                        placeholder="00"
                      />
                    </div>
                    <div className="box1">
                      <input
                        type="text"
                        value={recoveryCode}
                        onChange={handleChange}
                        placeholder=" 00"
                      />
                    </div> */}
                    </div>
                    <p data-theme={isDark ? "dark" : ""}>
                      Didn’t get a code?{" "}
                      <span data-theme={isDark ? "dark" : ""} onClick={handleResendOtp}>
                        Click to resend
                      </span>
                    </p>
                    {/* <Countdown date={Date.now() + 10000} />,
                    <p data-theme={isDark ? "dark" : ""}>
                      Didn’t get a code?{" "}
                      <span data-theme={isDark ? "dark" : ""}>
                        Click to resend
                      </span>
                    </p> */}
                    {/* {showResendMessage ? (
                      <p data-theme={isDark ? "dark" : ""}>
                        Didn’t get a code?{" "}
                        <span data-theme={isDark ? "dark" : ""}>
                          Click to resend
                        </span>
                      </p>
                    ) : (
                      <Countdown
                        date={Date.now() + 10000}
                        onComplete={() => setShowResendMessage(true)}
                      />
                    )} */}
                  </div>

                  <div
                    className="card-bottom"
                    data-theme={isDark ? "dark" : ""}
                  >
                    <div
                      className="signin-btn"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <button
                        onClick={handleContinue}
                        data-theme={isDark ? "dark" : ""}
                      >
                        Continue{" "}
                        {spinner && (
                          <span>
                            {" "}
                            {/* <Spinner
                              animation="border"
                              variant="primary"
                              size="sm"
                            /> */}
                            <Hourglass
                              visible={true}
                              height="15"
                              width="15"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              colors={
                                isDark ? ["#000", "#000"] : ["#fff", "#fff"]
                              }
                              data-theme={isDark ? "light" : "dark"}
                            />
                          </span>
                        )}
                      </button>
                      {/* <button
                      onClick={() => {
                        navigate("/accountreset");
                      }}
                    >
                      Continue
                    </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accountrecovery;
