import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import your translation files
import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/trasnlation.json";
import translationAR from "./locales/ar/translation.json";
import translationVI from "./locales/vi/translation.json";
import translationTA from "./locales/ta/translation.json";
import translationCHI from "./locales/chi/translation.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  ar: {
    translation: translationAR,
  },
  vi: {
    translation: translationVI,
  },
  ta: {
    translation: translationTA,
  },
  chi: {
    translation: translationCHI,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
