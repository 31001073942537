import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
const Protected = (props) => {
  const { Component } = props;
  const navigate = useNavigate();

  useEffect(() => {
    const loginData = secureLocalStorage.getItem("loginData");
    if (!loginData) {
      navigate("/signin");
    }
  }, []);

  return (
    <div>
      <Component />
    </div>
  );
};

export default Protected;
