import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import "./newChat.scss";
const NewChat = ({ onBackClick }) => {
    return (
        <div>
            <div className="new-chat">
                {/* <div className="back-btn" onClick={onBackClick}>
                    <Icon
                        icon="lets-icons:back"
                        width="24"
                        height="24"
                        style={{ color: "#2f4d8a" }}
                    />
                    Back
                </div> */}
                <div className="conversation">
                    <div className="answer">
                        <p>How are you?</p>
                    </div>
                    <div className="question">
                        <p>Hello.</p>
                    </div>
                </div>
                {/* Send us a message */}
                <div className="send-message">
                    <input type="text" name="" id="" placeholder="Enter your message" />
                    <Icon
                        className="send-icon"
                        icon="icon-park-outline:send-one"
                        width="30"
                        height="30"
                        style={{ color: "#304e8d" }}
                    />
                </div>
            </div>
        </div>
    );
};

export default NewChat;
