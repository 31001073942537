import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import Logos from "../assets/images/Group 138.svg";
import { useNavigate } from "react-router-dom";
import { forgotPasswordApi } from "../apis/api";
import useLocalStorage from "use-local-storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Hourglass } from "react-loader-spinner";
const Forgetemail = () => {
  const [spinner, setSpinner] = useState(false);
  const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDark, setIsDark] = useLocalStorage("isDark", preference);
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [EmailError, setEmailErrors] = useState("");
  const isValidEmail = (email) => {
    // Email regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignIn = () => {
    setSpinner(true);
    // Simple validation for email and password
    if (!Email) {
      setEmailErrors("Email is required");
      return;
    } else if (!isValidEmail(Email)) {
      setEmailErrors("Enter a valid email address");
      return;
    } else {
      forgotPasswordApi(Email)
        .then((response) => {
          setSpinner(false);
          console.log("response in forgot mail:", response);
          // alert("otp is sent to your mail");
          notify("Otp is sent to your Mail", "success");
          setTimeout(()=>{
            notify("Please check your spam folder if you do not receive the email","info")

          },2000)
          navigate("/accountrecovery", {
            state: { email: Email, screen: "forgetMail" },
          });
        })
        .catch((error) => {
          setSpinner(false);
          
          console.log("error in forgot mail:", error);
          notify(error?.response?.data?.error, "error");
        });
    }
  };
  const notify = (message, type = "success") => {
    toast[type](message, {
      position: "top-right",
      autoClose: 5000,
    });
  };

  return (
    <>
      {" "}
      <div className="background-account" data-theme={isDark ? "dark" : ""}>
        
        <div className="forgetemail" data-theme={isDark ? "dark" : ""}>
          <div
            className="forgetemail-content"
            data-theme={isDark ? "dark" : ""}
          >
            <div className="signup-logo">
              {/* <img src={Logo} alt="signuplogo" className="custom" /> */}
              <img src={Logos} alt="signuplogo" className="custom" />
              {/* <img src={Logoss} alt="signuplogo" className="custom" /> */}
            </div>
            <div className="signup-card" data-theme={isDark ? "dark" : ""}>
              <div
                className="signupcard-content"
                data-theme={isDark ? "dark" : ""}
              >
                <div className="main-card" data-theme={isDark ? "dark" : ""}>
                  <div className="cardtitle" data-theme={isDark ? "dark" : ""}>
                  <Icon icon="ep:back" width="38" height="38"  style={{color: "#3D6BC1", cursor: "pointer"}} onClick={()=>navigate(-1)}/>

                    <div className="leftside" data-theme={isDark ? "dark" : ""}>
                      <h6 data-theme={isDark ? "dark" : ""}>
                        Account recovery
                      </h6>
                    </div>
                    <div
                      className="rightside"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <h6 data-theme={isDark ? "dark" : ""}>
                        Recover your Email Account
                      </h6>
                    </div>
                  </div>
                  <div
                    className="accountRecovery-middle"
                    data-theme={isDark ? "dark" : ""}
                  >
                    <div
                      className="firstfield"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <div className="label" data-theme={isDark ? "dark" : ""}>
                        <label htmlFor="" data-theme={isDark ? "dark" : ""}>
                          Enter your Email
                        </label>{" "}
                      </div>
                      <div
                        className="inputfield"
                        data-theme={isDark ? "dark" : ""}
                      >
                        <input
                          type="email"
                          // type={showPassword ? "text" : "password"}
                          placeholder="Email"
                          value={Email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailErrors(""); // Clear error when user starts typing
                          }}
                          data-theme={isDark ? "dark" : ""}
                        />
                      </div>
                      <span style={{ color: "red" }} className="span">
                        {EmailError}
                      </span>
                    </div>
                  </div>

                  <div
                    className="card-bottom"
                    data-theme={isDark ? "dark" : ""}
                  >
                    <div
                      className="signin-btn"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <button
                        onClick={handleSignIn}
                        data-theme={isDark ? "dark" : ""}
                      >
                        Continue{" "}
                        {spinner && (
                          <span>
                            {" "}
                            {/* <Spinner
                              animation="border"
                              variant="primary"
                              size="sm"
                            /> */}
                            <Hourglass
                              visible={true}
                              height="15"
                              width="15"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              colors={
                                isDark ? ["#000", "#000"] : ["#fff", "#fff"]
                              }
                              data-theme={isDark ? "light" : "dark"}
                            />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgetemail;
