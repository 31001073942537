import React from "react";
import "./price.scss";
import Cardtick from "../../assets/images/cardtick.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Price = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className="price-card">
        <div className="card-content ">
          <div className="first-card">
            <div className="card-title">
              <span>{t("plan")}</span>
              <p>{t("planPara")}</p>
            </div>
            <div className="card-month">
              <div className="month-heading">
                <span className="dollar">$10{t("month")}</span>
                <span className="month">{t("3days")}</span>
              </div>
            </div>
            <div className="card-bottom">
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Self_help")} </span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Email_support")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Monthly_legal_advice")} </span>
                </div>
              </div>
              {/* <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("marketing")} </span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("planning")} </span>
                </div>
              </div> */}
            </div>
            <div className="card-button">
              <button
                onClick={() =>
                  navigate("/signin", { state: { forPlan: true } })
                }
              >
                {t("getStarted")}
              </button>
            </div>
          </div>
          <div className="first-card">
            <div className="card-title">
              <span>{t("basic")}</span>
              <p>{t("proPara")}</p>
            </div>
            <div className="card-month">
              <div className="month-heading">
                <span className="dollar">$25{t("month")}</span>
                <span className="month">{t("7days")}</span>
              </div>
            </div>
            <div className="card-bottom">
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("the_rest_listed")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Unlimited_AI")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Live_chat_support")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Priority_access")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Free_monthly")}</span>
                </div>
              </div>
              {/* <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("growth")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("review")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("business")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("marketing")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("planning")}</span>
                </div>
              </div> */}
            </div>
            <div className="card-button">
              <button
                onClick={() =>
                  navigate("/signin", { state: { forPlan: true } })
                }
              >
                {t("getStarted")}
              </button>
            </div>
          </div>
          <div className="first-card">
            <div className="card-title">
              <span>{t("proPlan")}</span>
              <p>{t("proPara")}</p>
            </div>
            <div className="card-month">
              <div className="month-heading">
                <span className="dollar">$50{t("month")}</span>
                <span className="month">
                  {/* {t("Month")} */}
                  {t("10days")}
                </span>
              </div>
            </div>
            <div className="card-bottom">
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("unlimitedAIlegalAdvice")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("liveChatSupport")} </span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span> {t("priorityAccessToAttorneys")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("freeMonthlyLegalConsultation")} </span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("the_rest_listed")}</span>
                </div>
              </div>
              {/*   <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("contract")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("review")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("business")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("marketing")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("planning")}</span>
                </div>
              </div> */}
            </div>
            <div className="card-button">
              <button
                onClick={() =>
                  navigate("/signin", { state: { forPlan: true } })
                }
              >
                {t("getStarted")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Price;
