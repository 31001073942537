import React, { useState, useEffect, useRef } from "react";
import "./chatbot.scss";
import Chatbotimg from "../../assets/images/chatbot-lawvocate.svg";
import { chatArrayData } from "../../constants/constants";
import NewChat from "./newChat";
import Contactus from "../contactus/contactus";

const Chatbot = () => {
  const [isIconToggled, setIsIconToggled] = useState(false);
  const chatContentRef = useRef(null);
  const [chatArray, setChatArray] = useState([]);
  useEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  }, [chatArray]);
  const handleClick = () => {
    setIsIconToggled(!isIconToggled);
  };
  const handleChatClick = (e) => {
    e.stopPropagation();
  };
  const [showOtherComponent, setShowOtherComponent] = useState(false);
  const handleBackToMessageSection = () => {
    setShowOtherComponent(false);
  };
  const onSendingData = (user, response) => {
    setChatArray((prev) => [...prev, { textBy: "user", answer: user }]);
    setTimeout(() => {
      setChatArray((prev) => [...prev, { textBy: "system", answer: response }]);
      console.log("chatArray", chatArray);
    }, 500);
  };
  return (
    <div className="chat-bot" onClick={handleClick}>
      <img
        src={Chatbotimg}
        alt=""
        // className={}
        className="imgchatbot"
      />
      {/* <img src={Chatbot} alt="..." /> */}

      <div
        className={`lawvocate-ai ${isIconToggled ? "show" : ""}`}
        onClick={handleChatClick}
      >
        <div className="lawvocate-top">
          <div className="lawvocate-top-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"n
              onClick={handleBackToMessageSection}
              style={{ cursor: "pointer" }}
            >
              <path
                d="M6.7162 8.285L11.3683 12.9371C11.5265 13.0953 11.603 13.2799 11.5977 13.4909C11.5924 13.7019 11.5107 13.8865 11.3524 14.0447C11.1942 14.2029 11.0096 14.282 10.7986 14.282C10.5876 14.282 10.403 14.2029 10.2448 14.0447L5.37122 9.18693C5.24463 9.06034 5.14969 8.91793 5.0864 8.7597C5.0231 8.60147 4.99146 8.44323 4.99146 8.285C4.99146 8.12677 5.0231 7.96853 5.0864 7.8103C5.14969 7.65207 5.24463 7.50966 5.37122 7.38307L10.2448 2.50948C10.403 2.35125 10.5903 2.27477 10.8065 2.28004C11.0228 2.28531 11.21 2.36707 11.3683 2.5253C11.5265 2.68354 11.6056 2.86814 11.6056 3.07912C11.6056 3.2901 11.5265 3.4747 11.3683 3.63294L6.7162 8.285Z"
                fill="white"
              />
            </svg>
            <p>Lawvocate AI</p>
          </div>
          <div className="lawvocate-top-right" onClick={handleClick}>
            {/* <img src={minimizeIcon} alt="" className=" pointer" /> */}
          </div>
        </div>
        {showOtherComponent ? (
          // <NewChat onBackClick={handleBackToMessageSection} />
          <Contactus onBackClick={handleBackToMessageSection} />
        ) : (
          <div
            className="message-section"
            style={{ overflowY: "auto", height: "78%" }}
            ref={chatContentRef}
          >
            <div className="lawvocate-messages">
              {chatArray.length === 0 ? (
                <>
                  <div className="message-container">
                    <p>Hi there!</p>
                  </div>
                  <div className="message-container">
                    <p>Welcome to Lawvocate AI. What brought you in Today?</p>
                  </div>
                </>
              ) : (
                chatArray.map((item, index) => (
                  <div
                    key={index}
                    className={`message-container ${
                      item.textBy === "user" ? "user-message" : "system-message"
                    }`}
                  >
                    <p>{item.answer}</p>
                  </div>
                ))
              )}
            </div>

            <div className="request-demo" style={{ paddingBottom: "10px" }}>
              <div
                className="request-btn"
                onClick={() => {
                  onSendingData(
                    chatArrayData[0].prompt,
                    chatArrayData[0].response
                  );
                }}
              >
                <p>How does it work?</p>
              </div>
              {/* Other request buttons */}
              <div
                className="request-btn"
                onClick={() => {
                  onSendingData(
                    chatArrayData[1].prompt,
                    chatArrayData[1].response
                  );
                }}
              >
                <p>What are the benefits?</p>
              </div>
              <div
                className="request-btn"
                onClick={() => {
                  onSendingData(
                    chatArrayData[2].prompt,
                    chatArrayData[2].response
                  );
                }}
              >
                <p>How can I subscribe?</p>
              </div>
              <div
                className="request-btn"
                onClick={() => {
                  onSendingData(
                    chatArrayData[3].prompt,
                    chatArrayData[3].response
                  );
                }}
              >
                <p>What if I have a question?</p>
              </div>
              <div
                className="request-btn"
                onClick={() => {
                  onSendingData(
                    chatArrayData[4].prompt,
                    chatArrayData[4].response
                  );
                }}
              >
                <p>Is there a refund policy?</p>
              </div>
              <button
                onClick={() => setShowOtherComponent(true)}
                style={{
                  color: "white",
                  padding: "6px",
                  margin: "18px 0 -10px 0",
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "#304e8d",
                  fontSize: "14px",
                }}
                className="w-100"
              >
                Send us a message
              </button>
              {/* Clear chats button */}
              <button
                onClick={() => setChatArray([])}
                style={{
                  color: "white",
                  padding: "6px",
                  margin: "18px 0 -10px 0",
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "#304e8d",
                  fontSize: "14px",
                }}
                className="w-100"
              >
                Clear chats
              </button>
            </div>
          </div>
        )}
        <div className="write-msg">
          <span>Powered by Lawvocate</span>
          {/* <input type="text" placeholder="Write a message" /> */}
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
