import React from "react";
import { useNavigate } from "react-router-dom";

const Success = () => {
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate('/Dashboard')
    }
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-3xl font-bold mb-4">Payment Successful</h1>
            <p className="text-lg">Thank you for your purchase!</p>
            <button onClick={handleNavigate}>Back</button>
        </div>
    );
};

export default Success;
