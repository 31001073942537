import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { chatApi, uploadApi } from "../../../apis/api";
import { ThreeDots } from "react-loader-spinner";
import {
  downloadPDF,
  TextWithLineBreaks,
  handleClickCopy,
  generateUniqueId,
  notify,
} from "../../../constants/constants";
import { Icon } from "@iconify/react";
import useLocalStorage from "use-local-storage";
import secureLocalStorage from "react-secure-storage";
import useDataStore from "../../../zustand/store";
import copyIcon from "../../../assets/images/copy.svg";
import like from "../../../assets/images/like.svg";
import dislike from "../../../assets/images/dislike.svg";
import reload from "../../../assets/images/reload.svg";
import { useTranslation } from "react-i18next";
import { socketInstance } from "../../../socket";
import { handleFileUpload } from "../../../constants/constants";

const Newchat = (props) => {
  const { chatmessage, isSocketOn } = props;
  const { t } = useTranslation();
  const { setData, concatenatedData, setConcatenatedData, iteration } =
    useDataStore();

  const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDark, setIsDark] = useLocalStorage("isDark", preference);
  const [textInput, setTextInput] = useState("");
  const textAreaRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);

  const [chatArray, setChatArray] = useState([]);

  const [isResponseActive, setIsResponseActive] = useState(false);
  // const chatContentRef = useRef(null);

  const chatContentRef = useRef(null);
  const chatScrollRef = useRef(null);

  const [chunkText, setChunkText] = useState("");
  const [fileUrl, setfileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [randomValue, setRandomValue] = useState("");
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
      setIsScrollable(textAreaRef.current.scrollHeight > 50);
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [textInput]);

  useEffect(() => {
    setChunkText((prev) => prev + concatenatedData);
  }, [concatenatedData]);

  useEffect(() => {
    chatDataDisplay();
  }, [chatmessage]);

  useEffect(() => {
    if (iteration == "done") {
      setChunkText("");
      setConcatenatedData("");
    }
  }, [iteration]);

  // useEffect(() => {
  //   if (chatContentRef.current) {
  //     chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
  //   }
  //   messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  // }, [chatArray, concatenatedData]);

  useEffect(() => {
    if (chatScrollRef.current) {
      chatScrollRef.current.scrollTop = chatScrollRef.current.scrollHeight;
    }

    setData(chatArray.length);
  }, [chatArray, concatenatedData, chunkText]);

  useLayoutEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatArray, concatenatedData]);

  const chatDataDisplay = () => {
    try {
      setChatArray(chatmessage.chatData);
      chatmessage.chatData &&
        chatmessage.chatData.map((data, index) => {
          var responseText;
          var responseContent;

          try {
            responseText = data.content;
            responseContent = false;
          } catch (error) {
            console.error(
              "Error occurred while parsing or accessing data:",
              error
            );
            // Handle the error here, you can assign a default value to responseText or do something else.
            // For example:
            responseText = "Error occurred while processing content, Try Again";
            responseContent = false;
          }
          return setChatArray((prevChatArray) => [
            ...prevChatArray,
            data.role == "user"
              ? {
                  sentBy: "user",
                  message: data.content,
                  timestamp: data.timestamp,
                  userID: data.userId,
                  fileUrl: data.fileUrl,
                }
              : {
                  sentBy: "gpt",
                  message: responseText,
                  contract: false,
                  timestamp: data.timestamp,
                  assistantid: data.assistantid,
                },
          ]);
        });
    } catch (error) {
      setChatArray(chatmessage.chatData);
      chatmessage.chatData &&
        chatmessage.chatData.map((data, index) =>
          setChatArray((prevChatArray) => [
            ...prevChatArray,
            data.role == "user"
              ? {
                  sentBy: "user",
                  message: data.content,
                  timestamp: data.timestamp,
                }
              : {
                  sentBy: "gpt",
                  message: "Unable to display the message",
                  contract: false,
                  timestamp: data.timestamp,
                },
          ])
        );
    }
  };

  const chatResponse = (
    chatText,
    regenrate,
    questions,
    assistantid,
    userID,
    file
  ) => {
    const loginData = secureLocalStorage.getItem("loginData");
    const uniqueTextId = generateUniqueId();

    if (regenrate) {
      chatArray.pop();
    } else {
      setChatArray((prevChatArray) => [
        ...prevChatArray,
        {
          sentBy: "user",
          message: regenrate || questions ? chatText : textInput,
          userID: uniqueTextId,
          fileUrl: fileUrl ? fileUrl : null,
        },
      ]);
    }
    var responseText;
    var responseContent;
    var responseRegenrate;

    chatApi(
      regenrate || questions ? chatText : textInput,
      loginData.email,
      JSON.stringify(chatmessage.chatCreationTime),
      chatmessage.chatID,
      JSON.stringify(new Date().getTime()),
      regenrate,
      userID ? userID : uniqueTextId,
      assistantid,
      fileUrl ? fileUrl : file ? file : ""
    )
      .then((response) => {
        console.log(response);
        if (response?.data?.queryResponse) {
          try {
            responseText = response?.data?.queryResponse;
            responseContent = false;
            responseRegenrate = response?.data?.assistantID;
          } catch (error) {
            responseText = "Error occurred while processing content, Try Again";
            responseContent = false;
          }
          setfileUrl("");
          setConcatenatedData("");
          setChunkText("");

          setChatArray((prevChatArray) => [
            ...prevChatArray,
            {
              sentBy: "gpt",
              message: responseText,
              contract: responseContent,
              assistantid: responseRegenrate,
            },
          ]);
        } else {
          setChatArray((prevChatArray) => prevChatArray.slice(0, -1));
        }
      })
      .catch((err) => {
        setfileUrl("");
        setConcatenatedData("");
        setChunkText("");
        notify("Failed to extract text from the document.", "error", 4000);
        console.log("err in newChat", err);
        setChatArray((prevChatArray) => prevChatArray.slice(0, -1));
      });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && e.shiftKey) {
      e.preventDefault();
      const start = e.target.selectionStart;
      const end = e.target.selectionEnd;
      setTextInput(
        (prevMessage) =>
          prevMessage.substring(0, start) + "\n" + prevMessage.substring(end)
      );
      const newPos = start + 1;
      e.target.setSelectionRange(newPos, newPos);
    } else if (e.keyCode === 13) {
      if (textInput !== "") {
        chatResponse("", false, false);
        setTextInput("");
      }
      e.preventDefault();
    }
  };

  const welcomeMessage = [
    t("How_Can_I_Make_Your_Day_Better?"),
    t("Hi!_What_Can_I_Help_You_Discover_Today?"),
    t("Greetings!_How_may_I_be_of"),
    t("Hello!_What_can_I_assist"),
    t("Welcome!_How_can_I_support"),
    t("Hi_there!_What_can_I_do_for_you_today?"),
    t("Hello!_What's_something_new"),
    t("Greetings!_How_may_I_be_of"),
    t("Welcome!_What_challenge"),
    t("Good_to_see_you!_How_can_I"),
  ];

  useEffect(() => {
    // Function to generate a random value from the array
    const generateRandomValue = () => {
      const randomIndex = Math.floor(Math.random() * welcomeMessage.length);
      return welcomeMessage[randomIndex];
    };

    // Set initial random value on component mount
    setRandomValue(generateRandomValue());
    // Add event listener for window reload
    const handleReload = () => {
      setRandomValue(generateRandomValue());
    };
    window.addEventListener("beforeunload", handleReload);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("beforeunload", handleReload);
    };
  }, [randomValue]);
  const handleFileUpload = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".doc,.pdf , .docx"; // Specify accepted file types if needed
    fileInput.click();
    fileInput.addEventListener("change", handleFileChange);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    console.log("formData", formData);
    uploadApi(formData)
      .then((response) => {
        console.log("response in upload", response);
        setfileUrl(response.data.file_url);
        setFileName(file.name); // Set the file name
        notify("File Uploaded", "success", 2000);
      })
      .catch((err) => {
        console.log("error in upload", err);
      });
    // Process the uploaded file here, e.g., send it to a server or display it
    console.log("Uploaded file:", file);
  };
  const removeFile = () => {
    setfileUrl("");
    setFileName("");
  };
  return (
    <>
      <div className="newchat" data-theme={isDark ? "dark" : ""}>
        <div
          className="newchat-content"
          ref={chatContentRef}
          data-theme={isDark ? "dark" : ""}
        >
          {chatArray.length === 0 && (
            <>
              <div className="chatTitle">
                <div className="titleContent" data-theme={isDark ? "dark" : ""}>
                  <h6>{t("Hello_There")}</h6>
                  <span>{randomValue}</span>
                </div>
                {/* <div className="titleContent" data-theme={isDark ? "dark" : ""}>
                  <span>
                    Review and research Business, Marketing, Legal Matters,
                    Intellectual Property matters, Business Formation, Tech
                    Support, and Digital Contracting.
                  </span>
                </div> */}
              </div>
            </>
          )}

          {chatArray.length === 0 ? (
            <>
              {" "}
              <div
                className="parent-divquestion"
                data-theme={isDark ? "dark" : ""}
              >
                <div
                  className="title-question"
                  data-theme={isDark ? "dark" : ""}
                >
                  <div
                    className="left-question"
                    data-theme={isDark ? "dark" : ""}
                  >
                    {" "}
                    <button
                      className="A"
                      data-theme={isDark ? "dark" : ""}
                      onClick={() => {
                        // setTextInput();
                        chatResponse(
                          "Can you explain copyright laws?",
                          false,
                          true
                        );
                      }}
                    >
                      {t("explainCopyrightLaws")}
                    </button>
                    <button
                      className="B"
                      data-theme={isDark ? "dark" : ""}
                      onClick={() => {
                        // setTextInput();
                        chatResponse(
                          "what are the basics of smart contract?",
                          false,
                          true
                        );
                      }}
                    >
                      {" "}
                      {t("basicsOfSmartContract")}
                    </button>
                  </div>
                  <div className="left-question">
                    <button
                      className="C"
                      data-theme={isDark ? "dark" : ""}
                      onClick={() => {
                        // setTextInput();
                        chatResponse(
                          "How do i form a tech startup?",
                          false,
                          true
                        );
                      }}
                    >
                      {" "}
                      {t("formTechStartup")}
                    </button>
                    {/* <button
                      className="D"
                      data-theme={isDark ? "dark" : ""}
                      onClick={() => {

                        chatResponse(
                          "Can you review this digital contract?",
                          false,
                          true
                        );
                      }}
                    >
                      {" "}
                      {t("reviewDigitalContract")}
                    </button> */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div
              className="chatContent"
              ref={chatScrollRef}
              // ref={chatContentRef}
              // ref={messagesEndRef}
              data-theme={isDark ? "dark" : ""}
            >
              {chatArray &&
                chatArray.map((value, index) => (
                  <>
                    {value.sentBy === "user" && (
                      <div key={value.timestamp} className="userCommand">
                        <div
                          className="userQuestion"
                          data-theme={isDark ? "dark" : ""}
                        >
                          <span>{value.message}</span>
                          <span>
                            {value.fileUrl ? (
                              <>
                                <div
                                  onClick={() => {
                                    window.open(value.fileUrl, "_blank");
                                  }}
                                >
                                  <Icon
                                    icon="vscode-icons:file-type-pdf2"
                                    width="24"
                                    height="24"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </>
                            ) : null}
                          </span>
                        </div>
                      </div>
                    )}

                    {value.sentBy === "gpt" && (
                      <>
                        <div
                          // key={value.timestamp}
                          className="commandResponse"
                          data-theme={isDark ? "dark" : ""}
                        >
                          <div
                            className="userQuestion"
                            data-theme={isDark ? "dark" : ""}
                          >
                            <span style={{textTransform:"none"}}>
                              {<TextWithLineBreaks text={value.message} streaming={false}/>}
                            </span>
                            {/* {console.log("contract:", value.contract)} */}
                            {value.message.includes("@@@") ? (
                              <span
                                onClick={() => {
                                  downloadPDF(chatArray, index);
                                }}
                                style={{ marginLeft: "10px" }}
                              >
                                <b>
                                  <Icon
                                    icon="vscode-icons:file-type-pdf2"
                                    width="50"
                                    height="50"
                                    style={{ cursor: "pointer" }}
                                  />
                                </b>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="d-flex gap-2 response-actions  mb-1 ">
                          <img
                            src={copyIcon}
                            alt=""
                            onClick={() => handleClickCopy(value.message)}
                            className={`pointer ${isDark ? "" : "invert-1"}`}
                          />
                          {chatArray.length - 1 == index && (
                            <img
                              src={reload}
                              alt=""
                              className={`pointer ${isDark ? "" : "invert-1"}`}
                              onClick={() => {
                                console.log(
                                  "chat array on onClick:",
                                  chatArray[chatArray.length - 2]
                                );
                                chatResponse(
                                  chatArray[chatArray.length - 2].message,
                                  true,
                                  false,
                                  chatArray[chatArray.length - 1].assistantid,
                                  chatArray[chatArray.length - 2].userID,
                                  chatArray[chatArray.length - 2].fileUrl
                                );
                              }}
                            />
                          )}

                          <img
                            src={like}
                            alt=""
                            className={`pointer ${isDark ? "" : "invert-1"}`}
                          />
                          <img
                            src={dislike}
                            alt=""
                            className={`pointer ${isDark ? "" : "invert-1"}`}
                          />
                        </div>
                      </>
                    )}
                    <div ref={messagesEndRef} />
                  </>
                ))}

              {chunkText != "" && (
                <div
                  className="|"
                  style={{ width: "100%" }}
                  ref={chatContentRef}
                  data-theme={isDark ? "dark" : ""}
                >
                  <>
                    <div
                      // key={value.timestamp}
                      className="commandResponse" 
                      data-theme={isDark ? "dark" : ""}
                    >
                      <div
                        className="userQuestion"
                        data-theme={isDark ? "dark" : ""}
                      >
                        <span>{<TextWithLineBreaks text={chunkText} streaming={true}/>}</span>
                      </div>
                    </div>
                  </>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="userInput" data-theme={isDark ? "dark" : ""}>
          <div className="inputContent" data-theme={isDark ? "dark" : ""}>
            <div className="leftSide">
              {fileUrl ? (
                <div
                  className="filurl-div"
                  data-theme={isDark ? "dark" : ""}
                  // style={{
                  //   position: "absolute",
                  //   top: "-25px",

                  // }}
                >
                  <Icon
                    icon={
                      fileUrl.endsWith(".pdf")
                        ? "vscode-icons:file-type-pdf2"
                        : "vscode-icons:file-type-word"
                    }
                    // icon="vscode-icons:file-type-pdf2"
                    width="24"
                    height="24"
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      // position: "absolute",
                      // top: "-20px",
                    }}
                  />
                  <div
                    className="filename-div"
                    data-theme={isDark ? "dark" : ""}
                  >
                    <span
                      className="filename"
                      data-theme={isDark ? "dark" : ""}
                    >
                      {fileName}{" "}
                      <Icon
                        icon="gridicons:cross"
                        width="20"
                        height="20"
                        style={{
                          color: isDark ? "#ffffff" : "#5B5B5B",
                          cursor: "pointer",
                        }}
                        onClick={removeFile}
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  data-theme={isDark ? "dark" : ""}
                  alt="Click to upload"
                  style={{
                    color: isDark ? "white" : "black",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    if (isSocketOn) {
                      handleFileUpload();
                    }
                  }}
                >
                  <path
                    d="M10.5 22C8.96667 22 7.66667 21.4667 6.6 20.4C5.53333 19.3333 5 18.0333 5 16.5V6C5 4.9 5.39167 3.95833 6.175 3.175C6.95833 2.39167 7.9 2 9 2C10.1 2 11.0417 2.39167 11.825 3.175C12.6083 3.95833 13 4.9 13 6V15.5C13 16.2 12.7583 16.7917 12.275 17.275C11.7917 17.7583 11.2 18 10.5 18C9.8 18 9.20833 17.7583 8.725 17.275C8.24167 16.7917 8 16.2 8 15.5V6H9.5V15.5C9.5 15.7833 9.59583 16.0208 9.7875 16.2125C9.97917 16.4042 10.2167 16.5 10.5 16.5C10.7833 16.5 11.0208 16.4042 11.2125 16.2125C11.4042 16.0208 11.5 15.7833 11.5 15.5V6C11.5 5.3 11.2583 4.70833 10.775 4.225C10.2917 3.74167 9.7 3.5 9 3.5C8.3 3.5 7.70833 3.74167 7.225 4.225C6.74167 4.70833 6.5 5.3 6.5 6V16.5C6.5 17.6 6.89167 18.5417 7.675 19.325C8.45833 20.1083 9.4 20.5 10.5 20.5C11.6 20.5 12.5417 20.1083 13.325 19.325C14.1083 18.5417 14.5 17.6 14.5 16.5V6H16V16.5C16 18.0333 15.4667 19.3333 14.4 20.4C13.3333 21.4667 12.0333 22 10.5 22Z"
                    fill={isDark ? "white" : "black"}
                  />
                </svg>
              )}

              {/* <label htmlFor="file" className="pointer">
                <Icon
                  icon="iconamoon:attachment-light"
                  color="black"
                  width="24"
                  height="24"
                  rotate={2}
                />
                <input type="file" name="" id="file" className="d-none" />
              </label> */}
              <textarea
                type="text"
                placeholder={t("messageToLawvocateAI")}
                value={textInput}
                ref={textAreaRef}
                onChange={(e) => {
                  setTextInput(e.target.value);
                }}
                data-theme={isDark ? "dark" : ""}
                onKeyDown={(e) => {
                  if (isSocketOn) {
                    handleKeyDown(e);
                  }
                }}
                style={{
                  overflow: isScrollable ? "auto" : "hidden",
                  resize: "none",
                  maxHeight: "77px",
                  width: "100%",
                  border: "none",
                  paddingTop: "20px",
                  outline: "none",
                }}
              />
            </div>
            <div className="rightSide" data-theme={isDark ? "dark" : ""}>
              <div
                className="rightside-icon"
                data-theme={isDark ? "dark" : ""}
                onClick={() => {
                  if (textInput != "" && isSocketOn) {
                    chatResponse("", false, false);
                    setTextInput("");
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M18.8708 15.1667H4.66663V12.8334H18.8708L12.3375 6.30002L14 4.66669L23.3333 14L14 23.3334L12.3375 21.7L18.8708 15.1667Z"
                    fill="#9D9D9D"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newchat;
