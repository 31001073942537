import React, { useEffect } from "react";
import Agentcard from "../chatagent/agentcard";
import { useTranslation } from "react-i18next";
import Ai from "../../assets/images/groupAi.png";
import Contract from "../../assets/images/groupContract.png";
import Business from "../../assets/images/groupBuisness.png";
import Market from "../../assets/images/groupMarket.png";
import planning from "../../assets/images/groupPlanning.png";
import Legal from "../../assets/images/groupLegal.png";
import Live from "../../assets/images/groupLive.png";
import Review from "../../assets/images/groupReview.png";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
const OurServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  const services = [
    {
      img: Ai,
      heading: t("AI_Chat_Agent"),
      subheading: t("AI_Chat_Agent_para"),
    },
    {
      img: Contract,
      heading: t("Contract_Generation_b2c"),
      subheading: t("Contract_Generation_para_b2c"),
    },
    {
      img: Legal,
      heading: t("Legal_Advice"),
      subheading: t("Legal_Advice_para"),
    },
    {
      img: Business,
      heading: t("Business_Advice_b2c"),
      subheading: t("Business_Advice_para_b2c"),
    },
    {
      img: Market,
      heading: t("Marketing_Advice_b2c"),
      subheading: t("Marketing_Advice_para_b2cc"),
    },
    {
      img: planning,
      heading: t("Planning"),
      subheading: t("Planning_para"),
    },
    {
      img: Review,
      heading: t("Document_Review"),
      subheading: t("Document_Review_para"),
    },
    {
      img: Live,
      heading: t("Live_Expert_Consultation"),
      subheading: t("Live_Expert_Consultation_para"),
    },
  ];
  return (
    <>
      <Navbar />
      <div className="chatagent pt-5">
        <div className="chatagent-content pt-5 mt-5">
          <div className="chat-heading">
            <h5>{t("Our Services ")}</h5>
            <p></p>
          </div>
          <div className="agent-cards">
            {services.map((service, index) => (
              <Agentcard
                key={index}
                img={service.img}
                heading={service.heading}
                subheading={service.subheading}
              />
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default OurServices;
