import React, { useState } from "react";
import "./frequently.scss";
import Accordion from "react-bootstrap/Accordion";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import { useLocation } from "react-router-dom";

const Frequently = ({ type }) => {
  const location = useLocation();
  const pathsToHide = ["/b2c"];
  const shouldHideComponent = pathsToHide.includes(location.pathname);

  const accordionDataB2B = [
    {
      title: "What is Lawvocate.AI?",
      content:
        " Lawvocate.AI is an AI-powered platform that provides white-labeled legal and business advice tools for companies to integrate into their services.",
    },
    {
      title: "How does the free trial work?",
      content:
        "Depending on your plan, you can enjoy a free trial ranging from 3 to 10 days. You can explore all the plan's features during this period without charges.",
    },
    {
      title: "Can I cancel my subscription anytime?",
      content:
        "Yes, you can cancel your subscription at any time. There are no long-term commitments.",
    },
    {
      title: "Is my data secure with Lawvocate.AI?",
      content:
        "Absolutely. We prioritize your privacy and data security. All your information is encrypted and securely stored.",
    },
    {
      title: "Can I customize the AI tools for my business?",
      content:
        "Our white-labeled solutions are fully customizable to fit your brand and business needs.",
    },
  ];
  const accordionDataB2C = [
    {
      title: "What is Lawvocate.AI?",
      content:
        "Lawvocate.AI is an AI-powered platform that provides legal and business advice, contract generation, and more to help small businesses and entrepreneurs succeed. Lawvocate.AI is an AI-powered business and legal superpower that offers various services, including contract generation, legal advice, business advice, marketing advice, planning, document review, and live expert consultation.",
    },
    {
      title: "How does the free trial work?",
      content:
        "Depending on your plan, you can enjoy a free trial ranging from 3 to 10 days. You can explore all the plan's features during this period without charge.",
    },
    {
      title: "Can I cancel my subscription anytime?",
      content:
        "Yes, you can cancel your subscription at any time. There are no long-term commitments.",
    },
    {
      title: "Is my data secure with Lawvocate.AI?",
      content:
        "Absolutely. We prioritize your privacy and data security. All your information is encrypted and securely stored.",
    },
    {
      title: "How does the AI chat agent work?",
      content:
        "Our AI chat agent uses advanced algorithms to understand your queries and provide quick and accurate responses. It is available 24/7 to assist you with your business and legal needs.",
    },
    {
      title: "What is included in the contract generation service?",
      content:
        "Our contract generation service allows you to easily create professional and legally sound contracts. You can customize the contracts to suit your needs, and our team of experts is available to review them.",
    },
    {
      title: "What kind of legal advice do you provide?",
      content:
        "Our legal experts can advise on various legal matters, from contract review to legal consultation. We can also assist with document review and creation.",
    },
    {
      title: "What kind of business advice do you provide?",
      content:
        "We offer business advice to help you navigate the business landscape and make informed decisions that will drive your business growth. This includes advice on growth strategies, planning, and marketing.",
    },
    {
      title: "How can I schedule a consultation with a live expert?",
      content:
        "You can schedule a consultation with our team of experts through our website. We offer consultations on various topics, including legal and business matters.",
    },
    {
      title: "What are the different plans you offer?",
      content:
        "We offer three plans: the Essential Plan for small businesses, the Growth Plan for power users, and the Pro Plan for power users who require unlimited daily responses and a free 15-minute legal consult with an attorney monthly. You can find more details about these plans on our website.",
    },
    {
      title: "How can I sign up for a plan?",
      content:
        "You can sign up for a plan through our website. Choose the plan that suits your needs and click 'Get Started' to begin the sign-up process.",
    },
  ];
  let data;
  data = shouldHideComponent ? accordionDataB2C : accordionDataB2B;
  return (
    <>
      {/* <Navbar /> */}
      <div className="frequently-section" id="Frequently ">
        <div className="frequently-content">
          {/* <div className="left">
            <div className="title">
              <h5>Frequently Asked Questions</h5>
            </div>
          </div> */}
          <div className="right">
            <h3 className="text-white mb-5 pb-3">Frequently Asked Questions</h3>

            <Accordion defaultActiveKey="0" flush className="accordian-main">
              {data.map((item, index) => (
                <Accordion.Item
                  key={index}
                  eventKey={index.toString()}
                  className="accordian-item"
                >
                  <Accordion.Header className="accordian-header">
                    {item.title}
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    <div className="body-content">
                      <p> {item.content}</p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
        <img
          src="../../assets/images/a.png"
          alt="firstimg"
          className="leftimg"
        />
        <img
          src="../../assets/images/b.png"
          alt="firstimg"
          className="rightimg"
        />
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default Frequently;
