import React, { useState, useEffect, useRef } from "react";
import Top from "../components/home3/top";
import Cards from "../components/home3/cards";
import Contactus from "../components/home3/Contact";
import Footer from "../components/footer/footer";
import Blogs from "../components/home3/Blogs";
import Chatbot from "../components/chatbot/chatbot";
import { useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import Advicecard from "../components/advicecard/advicecard";
import GetStarted from "../components/getStarted/getStarted";
import Pricingtab from "../components/pricingtab/pricingtab";
import Chatagent from "../components/chatagent/chatagent";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home3 = () => {
  const { t } = useTranslation();
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [acceptedPermanently, setAcceptedPermanently] = useState(false);
  // const navigate = useNavigate();
  // const modalRef = useRef(null);
  // if (!modalRef) {
  //   toast.error("Please check first");
  // }
  // const handleCloseFirstModal = () => {
  //   if (termsChecked && privacyChecked) {
  //     setShowFirstModal(false);
  //     setShowSecondModal(true);
  //   } else {
  //     toast.error("Accept Terms and Privacy policy", {
  //       toastId: "Agree error",
  //     });
  //   }
  // };

  // const handleCloseSecondModal = () => {
  //   if (cookiesAccepted) {
  //     setShowSecondModal(false);
  //     setAcceptedPermanently(true); // Update state and store to indicate permanent acceptance
  //     localStorage.setItem("acceptedPermanently", "true");
  //   } else {
  //     setShowFirstModal(true);
  //     toast.error("Accept Terms and Privacy policy", {
  //       toastId: "second modal",
  //     });
  //   }
  // };

  // const handleAcceptCookies = () => {
  //   setCookiesAccepted(true);
  //   setShowSecondModal(false);
  //   setAcceptedPermanently(true); // Update state and store to indicate permanent acceptance
  //   localStorage.setItem("acceptedPermanently", "true"); // Close the modal after accepting cookies
  // };

  // const handleTermsChange = () => {
  //   setTermsChecked(!termsChecked);
  // };

  // const handlePrivacyChange = () => {
  //   setPrivacyChecked(!privacyChecked);
  // };

  // useEffect(() => {
  //   const loginData = secureLocalStorage.getItem("loginData");
  //   const accepted = localStorage.getItem("acceptedPermanently");

  //   if (loginData) {
  //     navigate("/dashboard");
  //   }
  //   if (accepted) {
  //     setAcceptedPermanently(true);
  //     setShowFirstModal(false);
  //     setShowSecondModal(false);
  //   } else {
  //     setShowFirstModal(true);
  //   }

  //   // localStorage.clear();
  // }, []);

  return (
    <div>
      <Top />
      <Advicecard className="bg-theme" />
      {/* <GetStarted /> */}
      <div className="">
        <Pricingtab className="bg-theme" />
      </div>
      <div className=" bg-theme pt-5">{/* <Cards /> */}</div>
      <div className="bg-theme">
        <Chatagent />
      </div>
      {/* <Blogs /> */}
      <Footer />
      {/* <ToastContainer /> */}
      <Chatbot />
      {/* First modal */}
      {/* <Modal
        show={showFirstModal}
        onHide={handleCloseFirstModal}
        className="termsmodal"
        size="xl"
        centered
        ref={modalRef}
      >
        <Modal.Header className="termsmodal-header"></Modal.Header>
        <Modal.Body className="termsmodal-body">
          <div className="terms-modal">
            <div className="terms-header">
              <span>Terms of services</span>
            </div>
            <div className="middle-body h-auto">
              <div className="middle-top">
                <p>
                  The LawvocateAI agent is an artificial intelligence tool. It
                  is not, nor does it claim to be, a substitute for an attorney
                  or law firm. It does not provide legal advice, representation,
                  or any other legal service. Any agreements and advice
                  generated by this tool are not guaranteed to be suitable,
                  complete, or accurate for your specific needs or
                  circumstances. We strongly urge you to consult with a
                  qualified attorney before using any agreement or advice
                  generated by our tool. Use of our site, the Agent or
                  inferences from LawvocateAI does not create an attorney-client
                  relationship. It should not be used as a replacement for
                  competent legal advice from a licensed professional attorney
                  in your jurisdiction.
                </p>
                <p>
                  This website and its tools require express agreement with the
                  <span>Privacy Policy</span>
                  and
                  <span>Terms of Use</span>. Please Confirm your understanding.
                </p>
              </div>
            </div>
            
            <div className="checkbox-termsmodal">
              <div className="form-check formcheck-parent">
                <input
                  className="form-check-input formcheckinput"
                  type="checkbox"
                  id="tos"
                  checked={termsChecked}
                  onChange={handleTermsChange}
                />
                <label
                  className="form-check-label formchecklabel"
                  htmlFor="tos"
                >
                  <strong>{t("termsOfService")}</strong>
                </label>
                <a href="/termsofservice">(See Terms and conditions)</a>
              </div>
              <div className="form-check formcheck-parent">
                <input
                  className="form-check-input formcheckinput"
                  type="checkbox"
                  id="pp"
                  checked={privacyChecked}
                  onChange={handlePrivacyChange}
                />
                <label className="form-check-label formchecklabel" htmlFor="pp">
                  <strong> {t("PrivacyPolicy")}</strong>
                </label>
                <a href="/privacy-policy">(See Privacy Policy)</a>
              </div>
            </div>
            <div className="term-botton">
              <button
                className="agree"
                onClick={handleCloseFirstModal}
                disabled={!(termsChecked && privacyChecked)}
              >
                {t("agree")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
      {/* Second Modal */}
      {/* <Modal
        show={showSecondModal}
        onHide={handleCloseSecondModal}
        className="termsmodal cookies-modal"
        size="lg"
        centered
        backdrop="static" // Prevent closing when clicking outside
        keyboard={false} // Prevent closing with the escape key
        ref={modalRef}
      >
        <Modal.Body className="termsmodal-body cookies-modal-body">
          <div className="terms-modal">
            <div className="middle-body h-auto">
              <p className="text-center">{t("cookiesConsentMessage")}</p>
            </div>
            <div className="term-botton">
              <Button className="accept-cookies" onClick={handleAcceptCookies}>
                {t("acceptCookies")}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default Home3;
