import { useState } from "react";
import { useLogin } from "react-facebook";
import axios from "axios";
import { facebookSignIn } from "../apis/api";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { notify } from "../constants/constants";

const useFacebookLogin = () => {
  const { login, status, isLoading, error } = useLogin();
  const [userInfo, setUserInfo] = useState(null);
  const [longLivedToken, setLongLivedToken] = useState(null);
  const [expiresIn, setExpiresIn] = useState(null);
  const navigate = useNavigate()
  const handleFacebookLogin = async () => {
    try {
      const response = await login({
        scope: 'email',
      });

      const apiUrl = `https://graph.facebook.com/${process.env.REACT_APP_LATEST_API_VERSION}/oauth/access_token`;
      const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
      const appSecret = process.env.REACT_APP_FACEBOOK_APP_SECRET;
      const shortLivedToken = response.authResponse.accessToken;

      const params = {
        grant_type: "fb_exchange_token",
        client_id: appId,
        client_secret: appSecret,
        fb_exchange_token: shortLivedToken,
      };

      const longResponse = await axios.get(apiUrl, { params });
      const longLivedToken = longResponse.data.access_token;
      const expiresIn = longResponse.data.expires_in;

      setLongLivedToken(longLivedToken);
      setExpiresIn(expiresIn);

      console.log(longLivedToken);
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://graph.facebook.com/me?access_token=${longLivedToken}&fields=id,name,email,picture.width(640).height(640)`,
        headers: {},
      };

      const userResponse = await axios.request(config);
      console.log(userResponse.data);

      if (userResponse && userResponse.data.email) {

        facebookSignIn(userResponse.data.email, userResponse.data.name, userResponse.data.picture, userResponse.data.id).then((res)=>{
            console.log(userResponse.data)
            secureLocalStorage.setItem("loginData", {
              token: res.data.token,
              timestamp: res.data.token_timestamp,
              email: userResponse.data.email,
            });
            navigate("/Dashboard");
            // alert("login succefull!!!");
            // notify(t("Login_Successful"), "success");
          console.log("res:",res)
        }).catch((err)=>{
            console.log(err)
          })
  
        }
        else{
          notify("Your FB Account is restricted. We cannot log you in","error",4000)
          console.log("no email found");
       }
      setUserInfo(userResponse.data);
      

  
    } catch (error) {
      console.error("Error during Facebook login:", error.message);
    }
  };

  return {
    handleFacebookLogin,
    userInfo,
    longLivedToken,
    expiresIn,
    status,
    isLoading,
    error,
  };
};

export default useFacebookLogin;
