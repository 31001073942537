import React, { useState } from "react";
import Dashboardplan from "./dashboardplan";
import Language from "./language";
import useLocalStorage from "use-local-storage";
import WrappedStripePaymentForm from "./dashboardplan";
import Contactus from "../../contactus/contactus";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
const Settings = () => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState("subscription");
  const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDark, setIsDark] = useLocalStorage("isDark", preference);

  const toggleContent = (section) => {
    setActiveSection(section);
  };
  return (
    <>
      <div className="settings">
        <div className="settingsContent">
          <div className="setingHeader">
            <div className="settinghContent">
              <div className="toggle-container">
                <button
                  className={`toggle-button ${activeSection === "subscription" ? "active" : ""
                    }`}
                  onClick={() => toggleContent("subscription")}
                  data-theme={isDark ? "dark" : ""}
                >
                  {t("Subscription")}
                </button>
                <button
                  className={`toggle-button ${activeSection === "customer-support" ? "active" : ""
                    }`}
                  onClick={() => toggleContent("customer-support")}
                  data-theme={isDark ? "dark" : ""}
                >
                  {t("Customer_Support")}
                </button>
                <button
                  className={`toggle-button ${activeSection === "language" ? "active" : ""
                    }`}
                  onClick={() => toggleContent("language")}
                  data-theme={isDark ? "dark" : ""}
                >
                  {t("Language")}
                </button>
              </div>

              <div className="span-container">
                {activeSection === "subscription" && (
                  <WrappedStripePaymentForm
                    className={isDark ? "darkMode" : "lightMode"}
                  />
                )}
                {activeSection === "customer-support" && (
                  <div className="contact-dash">
                    <Contactus className={isDark ? "darkMode" : "lightMode"} />
                  </div>
                )}
                {activeSection === "language" && <Language />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
