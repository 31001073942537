import React, { useEffect, useState } from "react";
import Flagamerica from "../../../assets/images/america flag.svg";
import Flagfrench from "../../../assets/images/french.svg";
import Flagspanish from "../../../assets/images/spanish.svg";
import Flagportugese from "../../../assets/images/portugal-flag-icon.svg";
import Flaghindi from "../../../assets/images/india.svg";
import Flagjapanese from "../../../assets/images/japan-flag-png-xl 1.svg";
import Flagchinese from "../../../assets/images/CN-China-Flag-icon 1.svg";
import Flagkorean from "../../../assets/images/south-korea-flag-png-xl 1.svg";
import Flagsaudi from "../../../assets/images/saudi.png";
import useLocalStorage from "use-local-storage";
import { useTranslation } from "react-i18next";

const Language = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDark, setIsDark] = useLocalStorage("isDark", preference);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    const currentLanguage = localStorage.getItem("language");

    if (currentLanguage == "en") {
      setSelectedLanguage("English");
    } else if (currentLanguage == "es") {
      setSelectedLanguage("Spanish");
    } else if (currentLanguage == "chi") {
      setSelectedLanguage("Chinese");
    } else if (currentLanguage == "ar") {
      setSelectedLanguage("Arabic");
    } else if (currentLanguage == "vi") {
      setSelectedLanguage("Viatnamese");
    } else if (currentLanguage == "ta") {
      setSelectedLanguage("Tagalog");
    }
  });

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    switch (language) {
      case "English":
        i18n.changeLanguage("en");
        localStorage.setItem("language", "en");
        break;
      case "Spanish":
        i18n.changeLanguage("es");
        localStorage.setItem("language", "es");
        break;
      case "Chinese":
        i18n.changeLanguage("chi");
        localStorage.setItem("language", "chi");
        break;
      case "Arabic":
        i18n.changeLanguage("ar");
        localStorage.setItem("language", "ar");
        break;
      case "Viatnamese":
        i18n.changeLanguage("vi");
        localStorage.setItem("language", "vi");
        break;
      case "Tagalog":
        i18n.changeLanguage("ta");
        localStorage.setItem("language", "ta");
        break;
    }
    // console.log("language++ Selected", language);
  };

  return (
    <>
      <div id="google_translate_element"></div>

      {/* <img id="flag_image" style="display: none;" /> */}
      <div className="language">
        <div className="languageContent">
          <div className="mainlanguage">
            <div
              className={`languageContainer ${
                selectedLanguage === "English"
                  ? "selectedLanguage"
                  : "blurLanguage"
              }`}
              onClick={() => handleLanguageSelect("English")}
              data-theme={isDark ? "dark" : ""}
            >
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <span>English</span>
              </div>
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <img src={Flagamerica} alt="flag" />
                {selectedLanguage === "English" ? (
                  <div className="flagmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M10.652 21.0292L4.00195 14.3792L5.66445 12.7167L10.652 17.7042L21.3561 7L23.0186 8.6625L10.652 21.0292Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                ) : (
                  ""
                )}

                {/* */}
              </div>
            </div>
            {/* <div
              className={`languageContainer ${
                selectedLanguage === "French"
                  ? "selectedLanguage"
                  : "blurLanguage"
              }`}
              onClick={() => handleLanguageSelect("French")}
              data-theme={isDark ? "dark" : ""}
            >
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <span>French</span>
              </div>
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <img src={Flagfrench} alt="flag" />
                {selectedLanguage === "French" ? (
                  <div className="flagmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M10.652 21.0292L4.00195 14.3792L5.66445 12.7167L10.652 17.7042L21.3561 7L23.0186 8.6625L10.652 21.0292Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div> */}
            <div
              className={`languageContainer ${
                selectedLanguage === "Spanish"
                  ? "selectedLanguage"
                  : "blurLanguage"
              }`}
              onClick={() => handleLanguageSelect("Spanish")}
              data-theme={isDark ? "dark" : ""}
            >
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <span>Spanish</span>
              </div>
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <img src={Flagspanish} alt="flag" />
                {selectedLanguage === "Spanish" ? (
                  <div className="flagmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M10.652 21.0292L4.00195 14.3792L5.66445 12.7167L10.652 17.7042L21.3561 7L23.0186 8.6625L10.652 21.0292Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* <div
              className={`languageContainer ${
                selectedLanguage === "Portuguese"
                  ? "selectedLanguage"
                  : "blurLanguage"
              }`}
              onClick={() => handleLanguageSelect("Portuguese")}
              data-theme={isDark ? "dark" : ""}
            >
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <span>Portuguese</span>
              </div>
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <img src={Flagportugese} alt="flag" />
                {selectedLanguage === "Portuguese" ? (
                  <div className="flagmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M10.652 21.0292L4.00195 14.3792L5.66445 12.7167L10.652 17.7042L21.3561 7L23.0186 8.6625L10.652 21.0292Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div> */}
            {/* <div
              className={`languageContainer ${
                selectedLanguage === "Hindi"
                  ? "selectedLanguage"
                  : "blurLanguage"
              }`}
              onClick={() => handleLanguageSelect("Hindi")}
              data-theme={isDark ? "dark" : ""}
            >
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <span>Hindi</span>
              </div>
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <img src={Flaghindi} alt="flag" />
                {selectedLanguage === "Hindi" ? (
                  <div className="flagmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M10.652 21.0292L4.00195 14.3792L5.66445 12.7167L10.652 17.7042L21.3561 7L23.0186 8.6625L10.652 21.0292Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div> */}
            {/* <div
              className={`languageContainer ${
                selectedLanguage === "Japanese"
                  ? "selectedLanguage"
                  : "blurLanguage"
              }`}
              onClick={() => handleLanguageSelect("Japanese")}
              data-theme={isDark ? "dark" : ""}
            >
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <span>Japanese</span>
              </div>
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <img src={Flagjapanese} alt="flag" />
                {selectedLanguage === "Japanese" ? (
                  <div className="flagmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M10.652 21.0292L4.00195 14.3792L5.66445 12.7167L10.652 17.7042L21.3561 7L23.0186 8.6625L10.652 21.0292Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div> */}
            <div
              className={`languageContainer ${
                selectedLanguage === "Chinese"
                  ? "selectedLanguage"
                  : "blurLanguage"
              }`}
              onClick={() => handleLanguageSelect("Chinese")}
              data-theme={isDark ? "dark" : ""}
            >
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <span>Chinese</span>
              </div>
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <img src={Flagchinese} alt="flag" />
                {selectedLanguage === "Chinese" ? (
                  <div className="flagmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M10.652 21.0292L4.00195 14.3792L5.66445 12.7167L10.652 17.7042L21.3561 7L23.0186 8.6625L10.652 21.0292Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* <div
              className={`languageContainer ${
                selectedLanguage === "Korean"
                  ? "selectedLanguage"
                  : "blurLanguage"
              }`}
              onClick={() => handleLanguageSelect("Korean")}
              data-theme={isDark ? "dark" : ""}
            >
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <span>Korean</span>
              </div>
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <img src={Flagkorean} alt="flag" />
                {selectedLanguage === "Korean" ? (
                  <div className="flagmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M10.652 21.0292L4.00195 14.3792L5.66445 12.7167L10.652 17.7042L21.3561 7L23.0186 8.6625L10.652 21.0292Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div> */}
            <div
              className={`languageContainer ${
                selectedLanguage === "Arabic"
                  ? "selectedLanguage"
                  : "blurLanguage"
              }`}
              onClick={() => handleLanguageSelect("Arabic")}
              data-theme={isDark ? "dark" : ""}
            >
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <span>Arabic</span>
              </div>
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <img
                  src={Flagsaudi}
                  alt="flag"
                  style={{ width: "58px", height: "38px" }}
                />
                {selectedLanguage === "Arabic" ? (
                  <div className="flagmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M10.652 21.0292L4.00195 14.3792L5.66445 12.7167L10.652 17.7042L21.3561 7L23.0186 8.6625L10.652 21.0292Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className={`languageContainer ${
                selectedLanguage === "Viatnamese"
                  ? "selectedLanguage"
                  : "blurLanguage"
              }`}
              onClick={() => handleLanguageSelect("Viatnamese")}
              data-theme={isDark ? "dark" : ""}
            >
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <span>Viatnamese</span>
              </div>
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <img src={Flagchinese} alt="flag" />
                {selectedLanguage === "Viatnamese" ? (
                  <div className="flagmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M10.652 21.0292L4.00195 14.3792L5.66445 12.7167L10.652 17.7042L21.3561 7L23.0186 8.6625L10.652 21.0292Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className={`languageContainer ${
                selectedLanguage === "Tagalog"
                  ? "selectedLanguage"
                  : "blurLanguage"
              }`}
              onClick={() => handleLanguageSelect("Tagalog")}
              data-theme={isDark ? "dark" : ""}
            >
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <span>Tagalog (filipino) </span>
              </div>
              <div className="leftside" data-theme={isDark ? "dark" : ""}>
                <img src={Flagchinese} alt="flag" />
                {selectedLanguage === "Tagalog" ? (
                  <div className="flagmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M10.652 21.0292L4.00195 14.3792L5.66445 12.7167L10.652 17.7042L21.3561 7L23.0186 8.6625L10.652 21.0292Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Language;
