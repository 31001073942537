import Contactus from "../components/contactus/contactus";
import Footer from "../components/footer/footer";
import Navbar from "../components/navbar/navbar";
import '../style/contactPage.css'

const Contact = () => {
  return (
    <div className="contactPage">
      <Navbar />
      <Contactus />
      <Footer />
    </div>
  );
};

export default Contact;
