import { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Signup from "./page/signup";
import Accountrecovery from "./page/accountrecovery";
import Resetpassword from "./page/resetpassword";
import Signin from "./page/signin";
import Dashboard from "./page/dashboard";
import Success from "./components/dashboards/setings/stripe/success";
import Cancel from "./components/dashboards/setings/stripe/cancel";
import Protected from "./components/proctectedRoute/protected";
import "@rainbow-me/rainbowkit/styles.css";
import Forgetemail from "./page/forgetemail";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

import Home2 from "./components/home2/home2";
import Notfound from "./components/notfound";
import "./i18n/i18n";
import { useTranslation } from "react-i18next";
import useDataStore from "./zustand/store";
import Home3 from "./page/home3";
import { socketInstance } from "./socket";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./components/PrivacyPolicy/TermsOfServices";
import Contact from "./page/Contact";
import AboutUs from "./components/aboutUs/AboutUs";
import OurServices from "./components/services/OurServices";
import OurB2CServices from "./components/services/OurB2CServices";
import Frequently from "./components/frequently/frequently";
import Disclaimer from "./components/PrivacyPolicy/disclaimer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginForm from "./utils/facebook";
import { FacebookProvider } from "react-facebook";
import { GoogleOAuthProvider } from '@react-oauth/google';


function App() {
  const { i18n, t } = useTranslation();
  const { setConcatenatedData, setIteration } = useDataStore();
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  useEffect(() => {


 
    const currentLanguage = localStorage.getItem("language");

    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage);
    }
    // console.log("I am in main function");
    window.addEventListener("scroll", handleScroll, { passive: true });
    socketInstance.on("connect", (data) => {
      // console.log("I am in console");
      // console.log("sid::", data);
    });
    socketInstance.on("client", (data) => {
      // console.log("data in client:", typeof data);
      secureLocalStorage.setItem("sid", data);
    });

    socketInstance.on("message_finished", (finished) => {
      setIteration(finished);
      if (finished == "done") {
        setConcatenatedData("");
      }
    });

    socketInstance.on("chunk", (chunk) => {
      setConcatenatedData(chunk);
    });
    return () => {
      socketInstance.off("chunk");
      socketInstance.off("message_finished");
      window.removeEventListener("scroll", handleScroll);
    };


   
  }, []);

  return (
    <div className="App">
      <img
        src="/assets/images/topscroll.svg"
        alt="..."
        className="topscroll"
        style={{
          display: `${scrollPosition > 1080 ? "" : "none"}`,
        }}
        onClick={scrollToTop}
      />
{/* <ToastContainer></ToastContainer> */}
      {/* <ChangeLanguageButton /> */}
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>

    <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_APP_ID} version={process.env.REACT_APP_LATEST_API_VERSION}>
      
      <Routes>
        <Route path="/" element={<Home3 />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/email" element={<Forgetemail />} />
        <Route path="/accountrecovery" element={<Accountrecovery />} />
        <Route path="/accountreset" element={<Resetpassword />} />
        <Route path="/paymentSuccessful" element={<Success />} />
        <Route path="/paymentCancelled" element={<Cancel />} />
        <Route path="*" element={<Notfound />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/termsofservice" element={<TermsOfService />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/aboutus" element={<AboutUs isB2C={false} />} />
        <Route path="/aboutus-b2c" element={<AboutUs isB2C={true} />} />
        <Route path="/services" element={<OurServices />} />
        <Route path="/services-b2c" element={<OurB2CServices />} />
        <Route path="/faq" element={<Frequently />} />
        <Route
          path="/b2c"
          render={(props) => <Frequently {...props} type="b2c" />}
        />
        <Route
          path="/b2b"
          render={(props) => <Frequently {...props} type="b2b" />}
        />

        <Route
          path="/dashboard"
          element={<Protected Component={Dashboard} />}
          // element={<Dashboard />}
        />
      </Routes>
      </FacebookProvider>
      </GoogleOAuthProvider>

      {/* <Chatbot /> */}

      <></>
    </div>
  );
}

export default App;
