import { create } from "zustand";

const useDataStore = create((set) => ({
  data: null,
  setData: (data) => set({ data: data }),

  concatenatedData: "",
  setConcatenatedData: (data) => set({ concatenatedData: data }),

  iteration: "",
  setIteration: (data) => set({ iteration: data }),
}));

export default useDataStore;
