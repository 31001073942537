import React, { useEffect } from "react";

const TidycalEmbed = ({ path }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://asset-tidycal.b-cdn.net/js/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div className="tidycal-embed" data-path={path}></div>;
};

export default TidycalEmbed;
