import React, { useEffect, useState } from "react";
import "./contactus.scss";
import { contactApi } from "../../apis/api";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contactus(props) {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const notify = (message, type = "success") => {
    toast[type](message, {
      position: "top-right",
      autoClose: 2000,
    });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePhoneNumber = (number) => {
    const re = /^[0-9]{8,30}$/;
    return re.test(number);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (firstName.trim() === "") {
      notify("First name is required", "error");
      return;
    }

    if (lastName.trim() === "") {
      notify("Last name is required", "error");
      return;
    }

    if (!validatePhoneNumber(number)) {
      notify(
        "Phone number must be between 8 and 30 digits and contain only numbers",
        "error"
      );
      return;
    }

    if (!validateEmail(email)) {
      notify("Invalid email address", "error");
      return;
    }

    if (description.trim() === "") {
      notify("Description is required", "error");
      return;
    }

    contactApi(firstName, lastName, number, email, description)
      .then((response) => {
        console.log(response);
        notify("Submission successful!", "success");
      })
      .catch((err) => {
        notify("Error occurred", "error");
        console.log(err);
      });

    setFirstName("");
    setDescription("");
    setEmail("");
    setlastName("");
    setNumber("");
  };

  const handleInputChange = (setState, value, limit = null) => {
    if (value.length <= limit) {
      setState(value);
    }
    if (limit == null) {
      setState(value);
    }
  };

  const handleNumberChange = (e) => {
    const value = e.target.value;
    if (/^[0-9]*$/.test(value)) {
      handleInputChange(setNumber, value, 30);
    }
  };

  return (
    <section className={`contact-sec  ${props.className}`}>
      <form className="contact-card c-card" onSubmit={handleSubmit}>
        <h2>CONTACT US</h2>
        <div className="fields-div">
          <div className="ist">
            <input
              required
              type="text"
              value={firstName}
              placeholder="First Name"
              onChange={(e) =>
                handleInputChange(setFirstName, e.target.value, 20)
              }
            />
            <input
              value={lastName}
              type="text"
              placeholder="Last Name"
              onChange={(e) =>
                handleInputChange(setlastName, e.target.value, 20)
              }
            />
          </div>
          <input
            value={number}
            required
            type="tel"
            placeholder="Phone No."
            onChange={handleNumberChange}
          />
          <input
            value={email}
            required
            type="email"
            placeholder="E-mail"
            onChange={(e) => handleInputChange(setEmail, e.target.value, 30)}
          />
          <textarea
            required
            value={description}
            placeholder="Description"
            onChange={(e) => handleInputChange(setDescription, e.target.value)}
          ></textarea>
        </div>
        <button className="submit-btn" type="submit">
          Submit
        </button>
      </form>
    </section>
  );
}

export default Contactus;
