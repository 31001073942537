import React from "react";
import "./hero.scss";
import Navbar from "../navbar/navbar";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";

function Top() {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <section className="hero-main">
        <div className="hero-sec">
          <div className="hero-content">
            <h1 className="w-75">
              <span>Unlock your Growth with Lawvocate.AI </span>
              <br />
              Your AI-Powered Business and Legal Superpower
            </h1>
            <button onClick={() => navigate("/signup")}>
              Explore Lawvocate AI
            </button>
          </div>
          {/* <div className="middle-sec-h1">
            <h1>
              At <span> Lawvocate Ai</span>
            </h1>
            <p>we provide a comprehensive range of services to meet your business needs</p>
          </div> */}
        </div>

        <img
          src="/assets/images/banner1.png"
          alt="..."
          className="herofirstimg"
        />
        <img
          src="/assets/images/banner2.png"
          alt="..."
          className="herosecondimg"
        />
      </section>
    </>
  );
}

export default Top;
