import jsPDF from "jspdf";
import React from "react";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

const downloadPDF = (chatArray, index) => {
  var currentdate = new Date();
  var datetime =
    currentdate.getDate() +
    "/" +
    (currentdate.getMonth() + 1) +
    "/" +
    currentdate.getFullYear() +
    " @ " +
    currentdate.getHours() +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();

  const pdf = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
  });
  pdf.setFontSize(12);
  pdf.setFont("helvetica");
  const textWidth = 180;
  const lineHeight = 9;
  const margin = 15;
  let message = chatArray[index].message;
  message = message.replace(/@/g, "");
  message = message.replace(/####/g, "");
  message = message.replace(/###/g, "");
  message = message.replace(/###/g, "");
  message = message.replace(/\*\*/g, "");

  const messages = message.split("\n");
  let cursorY = margin;
  let currentPage = 1;
  messages.forEach((line) => {
    const textLines = pdf.splitTextToSize(line, textWidth);
    textLines.forEach((textLine) => {
      if (cursorY + lineHeight > pdf.internal.pageSize.height - margin) {
        pdf.addPage();
        currentPage += 1;
        cursorY = margin;
      }
      pdf.text(textLine, margin, cursorY);
      cursorY += lineHeight;
    });
  });

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(12);
  const heading =
    "\nImportant Disclaimer Regarding Sample Legal Agreements/Contracts";
  const headingText = heading.split("\n");
  headingText.forEach((line) => {
    const textLines = pdf.splitTextToSize(line, textWidth);
    textLines.forEach((textLine) => {
      if (cursorY + lineHeight > pdf.internal.pageSize.height - margin) {
        pdf.addPage();
        currentPage += 1;
        cursorY = margin;
      }
      pdf.text(textLine, margin, cursorY);
      cursorY += lineHeight;
    });
  });

  pdf.setFont("helvetica", "normal");
  const text2 =
    "I am not a lawyer, and this sample agreement is provided for informational purposes only. It should not be considered legal advice or a substitute for consulting with a qualified attorney. \n\nUsing this template without proper legal review and modification for your specific business and industry can expose you to significant legal and financial risks. Every business has unique legal needs, and this sample agreement may not be suitable for your specific circumstances. Additionally, laws and  regulations may change frequently, making it crucial to seek legal counsel to ensure your agreement complies with all current legal requirements. \n\nLawvocateAI strongly advises you to consult a lawyer specializing in business law and technology to draft a clickwrap agreement tailored to your needs. They can help you understand the legal implications of various  clauses, ensure compliance with relevant laws and regulations, and address any potential risks associated with your business model and data practices. \n\n \u2022 You are solely responsible for all consequences of using this sample agreement. \n \u2022 We as a company are not liable for any damages or losses you may incur due to using this agreement or relying on the information provided herein. \n \u2022 A qualified attorney should review and modify this sample agreement before incorporating it into your business practices.";
  const message2text = text2.split("\n");
  message2text.forEach((line) => {
    const textLines = pdf.splitTextToSize(line, textWidth);
    textLines.forEach((textLine) => {
      if (cursorY + lineHeight > pdf.internal.pageSize.height - margin) {
        pdf.addPage();
        currentPage += 1;
        cursorY = margin;
      }
      pdf.text(textLine, margin, cursorY);
      cursorY += lineHeight;
    });
  });

  pdf.save(`pdfument_${datetime}.pdf`);
};

// const TextWithLineBreaks = ({ text }) => {
//   if (text.includes("@@@")) {
//     text =
//       text +
//       "\nDisclaimer \n This is a sample and may not be suitable for your needs. Consult a lawyer before using it.\n";
//   }
//   text = text.replace(/@/g, "");
//   text = text.replace(/####/g, "");
//   text = text.replace(/###/g, "");
//   text = text.replace(/\*\*/g, "");
//   const boldRegex = /\*\*(.*?)\*\*/g;
//   const formattedText = text.split(boldRegex).map((part, index) => {
//     if (part.match(boldRegex)) {
//       return React.createElement(
//         "strong",
//         { key: index, style: { fontSize: "24px" } },
//         part.replace(/\*\*/g, "")
//       );
//     } else {
//       return part;
//     }
//   });

//   // Split the text by newline characters and render each part
//   const textParts = formattedText.join("").split("\n");

//   return (
//     <div>
//       {textParts.map((part, index) => (
//         <React.Fragment key={index}>
//           {part}
//           <br />
//         </React.Fragment>
//       ))}
//     </div>
//   );
// };

const TextWithLineBreaks = ( props ) => {
 let {text, streaming} = props
  if (text.includes("@@@")) {
    text =
      text +
      "\n \nThe contract provided by Lawvocate.com and Lawvocate.ai is a sample template generated by an AI and is intended for informational purposes only. It may not cover all aspects specific to your legal needs. We strongly suggest you review this contract with a qualified attorney before you use it to ensure it's accurate and applicable to your situation. We encourage you to schedule a consultation with our highly experienced and knowledgeable legal team at LawvocateAI for further assistance.\n";

    text =
      text +
      "\n\n*To schedule a consultation with our helpful staff at LawvocateAI, please click here or contact us at support@lawvocate.ai.*\n";
    // text =
    //   text +
    //   "\nI am not a lawyer, and this sample agreement is provided for informational purposes only. It should not be considered legal advice or a substitute for consulting with a qualified attorney.\n";
    // text =
    //   text +
    //   "\nUsing this template without proper legal review and modification for your specific business and industry can expose you to significant legal and financial risks. Every business has unique legal needs, and this sample agreement may not be suitable for your specific circumstances. Additionally, laws and  regulations may change frequently, making it crucial to seek legal counsel to ensure your agreement complies with all current legal requirements.\n";

    // text =
    //   text +
    //   "\n  LawvocateAI strongly advises you to consult a lawyer specializing in business law and technology to draft a clickwrap agreement tailored to your needs. They can help you understand the legal implications of various  clauses, ensure compliance with relevant laws and regulations, and address any potential risks associated with your business model and data practices.\n";
    // text =
    //   text +
    //   "\nBy utilizing this sample agreement, you acknowledge and agree to the following:\n";
    // text =
    //   text +
    //   "\n \u2022 You are solely responsible for all consequences of using this sample agreement.\n";

    // text =
    //   text +
    //   "\n \u2022 We as a company are not liable for any damages or losses you may incur due to using this agreement or relying on the information provided herein.\n";

    // text =
    //   text +
    //   "\n \u2022 A qualified attorney should review and modify this sample agreement before incorporating it into your business practices.\n";
  }
  else if (streaming == false ){
  text =
      text +
      "\n\nThe responses provided by Lawvocate.com and Lawvocate.ai are generated by an AI and are for informational purposes only. They do not constitute legal advice. Please consult a licensed attorney for legal guidance";
  }
  text = text.replace(/@/g, "");
  text = text.replace(/####/g, "");
  text = text.replace(/###/g, "");
  text = text.replace(/\*\*/g, "");
  text = text.replace(/```/g, "");

  const boldRegex = /\*\*(.*?)\*\*/g;
  const formattedText = text.split(boldRegex).map((part, index) => {
    if (part.match(boldRegex)) {
      return (
        <strong key={index} style={{ fontSize: "24px", textTransform:"lowercase" }}>
          {part.replace(/\*\*/g, "")}
        </strong>
      );
    } else {
      return part;
    }
  });
  const textParts = formattedText.join("").split("\n");
  return (
    <p>
      {textParts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          <br />
        </React.Fragment>
      ))}
    </p>
  );
};

const generateUniqueId = () => {
  const timestamp = new Date().getTime().toString(36);
  const randomChars = Math.random().toString(36).substr(2, 5);
  return timestamp + randomChars;
};

const checkLoginExpiration = () => {
  const loginData = secureLocalStorage.getItem("loginData");
  if (loginData) {
    if (loginData.token && loginData.timestamp) {
      const currentTime = new Date().getTime();
      const tokenExpirationTime = new Date(loginData.timestamp).getTime();

      if (currentTime > tokenExpirationTime) {
        alert("your login session has expired. Try sign in again....");
        notify(
          "your login session has expired. Try sign in again....",
          "error",
          4000
        );
        secureLocalStorage.clear();

        return true;
      }

      return;
    }
  }
};

const notify = (message, type, time) => {
  toast[type](message, {
    position: "top-right",
    autoClose: time,
  });
};
const chatArrayData = [
  {
    prompt: "How does it work?",
    response:
      "Our Chat Agent is designed to provide legal and business constancy services to businesses and individuals. It offers advice on startup, growth, and technology solutions.",
  },
  {
    prompt: "What are the benefits",
    response:
      "By subscribing to our annual plan, you gain access to a wide range of legal and business resources. You also get a 30-day free trail to explore our services.",
  },
  {
    prompt: "How can I subscribe",
    response:
      "To Subscribe, simply visit our website and choose the annual plan that suits your needs. Follow the instructions to complete the subscription process.",
  },
  {
    prompt: "What if I have question",
    response:
      "If you have any questions or need further assistance, our support team is available to help. Feel free to reach out to us via email or phone.",
  },
  {
    prompt: "Is there a refund policy",
    response:
      "Yes, we offer a refund policy. If you are not satisfied with our services with in the first 30 days of your subscription, you can request a refund.",
  },
];

const handleClickCopy = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast.success("Text copied to clipboard");
    })
    .catch((err) => {
      console.error("Failed to copy text: ", err);
    });
};

export {
  downloadPDF,
  TextWithLineBreaks,
  generateUniqueId,
  checkLoginExpiration,
  notify,
  chatArrayData,
  handleClickCopy,
};
