import React from "react";
import "./privacy.css";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import { useTranslation } from "react-i18next";

function Disclaimer() {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <>
      <Navbar />
      <div className="privacyPolicy container">
        <h2 className="text-center ">Disclaimer</h2>
        <ol>
          <li>
            <h6 className="mt-5">No Legal Advice</h6>
            <p className="mt-2">
              Welcome to Lawvocate.com and Lawvocate.ai (hereinafter referred to
              as the "Websites"). The following disclaimer (the "Disclaimer")
              applies to your use of the Websites, including any services,
              features, content, and tools offered by [Your LLC Name] (the
              "Company," "we," "us," or "our"). By accessing or using the
              Websites, you agree to this Disclaimer in full. If you do not
              agree with any part of this Disclaimer, you should not use the
              Websites.
            </p>

            {/* <p>{t("Acceptance_of_Terms")}</p> */}
          </li>
          <li>
            <h6>No Attorney-Client Relationship</h6>
            <p className="mt-2">
              Your use of the Websites, including the submission of queries or
              receipt of responses from our AI agent, does not create an
              attorney-client relationship between you and the Company or any of
              its representatives. We highly recommend seeking professional
              legal counsel for your specific circumstances.
            </p>
          </li>
          <li>
            <h6>Limitation of Liability</h6>
            {/* <p>{t("Amendments")}</p> */}
            <p className="mt-2">
              The Company, its affiliates, partners, employees, agents, and
              licensors shall not be liable for any direct, indirect,
              incidental, consequential, or punitive damages arising out of your
              access to or use of the Websites. This includes, but is not
              limited to, damages for errors, omissions, or inaccuracies in the
              information provided, or any loss or damage of any kind incurred
              as a result of your use of any services or content made available
              via the Websites.
            </p>
          </li>
          <li>
            <h6> No Warranties</h6>
            <p>
              The information on the Websites is provided "as is" and "as
              available" without any warranties of any kind, either express or
              implied, including but not limited to implied warranties of
              merchantability, fitness for a particular purpose, or
              non-infringement. We do not warrant that the Websites will always
              be available, uninterrupted, secure, or free of errors.
            </p>
          </li>
          <li>
            <h6>AI Limitations</h6>
            <p className="mt-2">
              Our AI agent is designed to assist with general informational
              queries. However, the AI's responses may not always be accurate,
              complete, or up-to-date. The use of AI is inherently limited by
              current technology and as such, should only be used as a
              supplementary tool alongside professional legal advice.
            </p>
          </li>
          <li>
            <h6>Third-Party Links</h6>

            <p className="mt-2">
              The Websites may contain links to third-party websites or services
              not owned or controlled by the Company. We assume no
              responsibility for any third-party websites' content, privacy
              policies, or practices. Accessing these links is at your own risk,
              and reviewing their respective terms and conditions is advisable.
            </p>
          </li>
          <li>
            <h6>Changes to the Disclaimer</h6>

            <p className="mt-2">
              We may update this Disclaimer from time to time. The effective
              date at the beginning of this Disclaimer indicates the latest
              revision. Your continued use of the Websites following any changes
              signifies your acceptance.
            </p>
          </li>
          <li>
            <h6>Governing Law</h6>

            <p className="mt-2">
              If you have any questions or concerns about this Disclaimer or any
              other legal matter, don't hesitate to get in touch with us at
              help@lawvocate.ai
            </p>
          </li>

          <li>
            <h6>Contact Information</h6>

            <p className="mt-2">
              This Disclaimer and any related disputes shall be governed by and
              construed in accordance with the laws of Michigan, without regard
              to its conflict of law principles.
            </p>
          </li>
        </ol>
        <hr />
        <p>
          <strong>Effective Date: June 21, 2024</strong>
        </p>

        <p>
          <strong>
            Copyright © 2024 LawvocateAI LLC. All rights reserved.
          </strong>
        </p>
        <hr />

        <p>
          <strong>
            If you need any adjustments or further inclusions, feel free to let
            me know
          </strong>
        </p>
        {/* <h6>{t("UseAccessConditions")}</h6>
        <h6> {t("IntroductionAcceptance")}</h6>
        <p>{t("Introduction_and_Acceptance")}</p>
        <p>{t("Acceptance")}</p>
        <h6>{t("UserInformationConsent")}</h6> */}
        {/* <p>
          <strong>
            {" "}
            {t("DataCollectionUse")}: We collect the following personal data:{" "}
          </strong>
        </p> */}
        {/* <ul>
          <li>{t("ProvideDirectlyInformation")}</li>
          <li>
            {t("CollectedAutomaticallyInformation")}
            {t("DataUsage")}
          </li>
          <li> {t("ServiceProvision")}</li>
          <li>{t("MarketingCommunications")}</li>
          <li>{t("LegalCompliance")}</li>
          <li>{t("Contract")}</li>
          <li>{t("LegitimateInterest")}</li>
          <li>{t("Consent")}</li>
        </ul>
        <p>
          <strong>{t("LegalBasis")}</strong>
        </p>
        <ul>
          <li>{t("BasisContract")}</li>
          <li>{t("BasisLegitimateInterest")}</li>
          <li>{t("BasisConsent")}</li>
        </ul>
        <p>
          <strong>{t("UserRights")}</strong>
        </p>
        <ul>
          {" "} */}
        {/* {t("Acceptance_of_Terms")} */}
        {/* <li>{t("RightAccess")}</li>
          <li>{t("RightRectification")}</li>
          <li>{t("RightErasure")}</li>
          <li>{t("Right_to_restriction")}</li>
          <li>{t("RightDataPortability")}</li>
          <li>{t("RightObject")}</li>
        </ul>
        <p>{t("ExerciseRightsContact")}</p>
        <p>{t("Consent_for_Data_Processing")}</p>
        <h6> {t("ComplianceWithUETA")}</h6>
        <p>{t("Compliance_with_UETA")}</p>
        <h6> {t("AmendmentsUpdates")}</h6>
        <p>{t("Amendments_and_Updates")}</p>
        <h6> {t("DisputeResolution")}</h6>
        <p>{t("Dispute_Resolution")}</p>
        <h6> {t("Miscellaneous")}</h6>
        <p>{t("MiscellaneousDetail")}</p>
        <h6>{t("NonAgreementWarning")}.</h6>
        <h6>{t("LegalBasisProcessing")}</h6>

        <h6 className="pt-5 text-center">
          Copyright © {currentYear} LawvocateAI LLC. All rights reserved.
        </h6> */}
      </div>
      <Footer />
    </>
  );
}
export default Disclaimer;
